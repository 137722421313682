import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18next from "i18next";
import { StoreLocationActionCreators } from "../redux/actions";
import { getUserStore } from "../redux/selectors"
import LocationSearchInput from "../../locationSearchInput/container/locationSearchInput";
import { getClickAndCollectLoading, getLocationSearch } from "../../locationSearchInput/redux/selectors";
import {
    getOpeningHours,
    formatOpeningHours,
    dateStringFormat,
    setStoreToBasket,
    getbasketLineItems,
    Productoptions,
    getExistingProductInBasket,
    getShippingCity,
    getLocalStorageObject,
    createWebOrderLineItems,
    decryptCryptr,
    isLoggedin
} from "../../../helpers/helpers";
import BlackWindow from "../../common/blackWindow";
import closeIcon from "./../../../assets/img/close.svg";
import {
    STORE_DELIVERY,
    STORAGE_BASKET_INDEX,
    STORE_DEPOT,
    STOCK_LEFT,
    VALIDATE_DISCCOUNT,
    BRAND_ID,
    COUNTRY,
    ORDER_STATUS,
    PRODUCT_MAX_QUANTITY
} from "../../../utils/constants/variables";
import "./storeLocation.scss"
import Photo from "../../common/product/photo";
import Price from "../../common/product/price";
import { Dropdown, Icon } from "semantic-ui-react";
import Summary from "../../basket/container/components/summary";
import { getBasket } from "../../addToCart/redux/selectors";
import { OrderActionCreators } from "../../basket/redux/actions";
import { MesAddressActionCreators } from "../../monEspace/container/components/MesAddress/redux/actions";
import { getMesAddress } from "../../monEspace/container/components/MesAddress/redux/selectors";

interface Props {
    toggleStoreLocator?: any,
    locationSearchData?: any,
    userSetLocation?: any,
    storeLocationStart?: any,
    basket?: any,
    userStore?: any,
    textBtn?: string,
    product?: any,
    selectedQuantity?: any,
    selectQuantity?: any,
    selectedSize?: any,
    sizes?: any,
    selectSize?: any,
    isPayment: boolean,
    handleAddedToCart?: any,
    cartData?: any,
    parentID?: any,
    from?: string,
    changeAnullar?: any,
    isHourVisible?: boolean
}

interface State {
    showStoreLocator: boolean,
    showStoreCreated: boolean,
    productQTY: string,
    quantityQTY: string | number,
    hover: boolean,
    stockCount: number,
    isEmptyResponse: boolean,
    showCurrentProduct: boolean,
    selectedProduct: any,
    isMobile: false
}

class StoreLocator extends React.Component<Props & typeof StoreLocationActionCreators & State> {
    state: State = {
        showStoreLocator: false,
        showStoreCreated: false,
        productQTY: "",
        quantityQTY: "",
        hover: false,
        stockCount: 0,
        isEmptyResponse: false,
        showCurrentProduct: true,
        selectedProduct: {},
        isMobile: false,
    };


    componentDidMount() {
        const { sizes, selectSize, selectQuantity, selectedSize, selectedQuantity, isPayment, product, parentID, basket } = this.props;
        const selectedProduct = Array.isArray(sizes) && sizes.length ? sizes.filter(elem => elem.productReference === selectedSize)[0] : [];

        this.setState({ selectedProduct });

        if (!(selectedSize || selectedQuantity) && !isPayment && (product && Object.keys(product).length)) {
            selectSize(sizes[0].size, sizes[0].productReference);
            selectQuantity("1")
        }

        if (selectedProduct.productId) {
            const existingProduct = getExistingProductInBasket(
                parentID,
                selectedProduct.productId,
                basket
            );

            if (existingProduct.productId) {
                const { quantity } = existingProduct
                this.setState({
                    quantityQTY: quantity + 1,
                    productQTY: `${parentID}/${existingProduct.productId}`
                })
            }

        }
    }
    windowResize = () => {
        this.setState({
            isMobile: window.innerWidth < 948
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading && (!this.props.locationSearchData || !this.props.locationSearchData.length)) {
            this.setState({ isEmptyResponse: true })
        }
        if (prevProps.loading && !this.props.loading) {
            window.addEventListener('resize', this.windowResize)

        }
        const { sizes, selectedSize } = this.props;

        if (selectedSize && selectedSize !== prevProps.selectedSize) {
            const selectedProduct = Array.isArray(sizes) && sizes.length ? sizes.filter(elem => elem.productReference === selectedSize)[0] : [];
            this.setState({ selectedProduct });
        }

    }

    componentWillUnmount(): void {
        window.removeEventListener('reset', this.windowResize)
    }
    handleClickClose = (orderStatus = '') => {
        this.setState({ showStoreCreated: true, quantityQTY: "", productQTY: "" });
        this.props.locationSearchDestroyedStart();
        return setTimeout(this.props.toggleStoreLocator(orderStatus), 1000);
    };
    selectLocationDilevery = (item, type = "") => {
        const {
            cartData,
            parentID,
            isPayment,
            sizes,
            selectedSize,
            selectedQuantity,
            address,
            changeAnullar,
            from
        } = this.props;

        setStoreToBasket({ StoreInfo: item, storeDelivery: type });
        this.props.getBasketStart();
        this.props.storeLocationStart(item);
        if (type === "DIS") {
            this.props.selectedDeliveryMethodStart("onlineDelivery")
        } else if (type === "STO") {
            this.props.locationSearchDestroyedStart();
            this.props.selectedDeliveryMethodStart("storeDelivery");
        }
        this.handleClickClose(type);
        from === "deliveryPayment" && changeAnullar('notanulled');
        if (!isPayment && selectedQuantity && selectedSize) {
            const webInventory = sizes[selectedSize] && sizes[selectedSize].webInventory;
            cartData[parentID].size.quantity = cartData[parentID].quantity ? parseInt(cartData[parentID].quantity) : webInventory ? parseInt(webInventory) : parseInt(selectedQuantity);
            cartData[parentID].size.webInventory = webInventory ? parseInt(webInventory) : 0;
            delete cartData[parentID].quantity;
            this.props.addtocartStart(STORAGE_BASKET_INDEX, parentID, { ...cartData[parentID] });

            if (isLoggedin()) {
                const basket = getLocalStorageObject(STORAGE_BASKET_INDEX);
                const validateDiscount = getLocalStorageObject(VALIDATE_DISCCOUNT);
                const storeCode = basket.store && basket.store.StoreInfo && basket.store.StoreInfo.storeCode || ''
                const { webOrderLineItems } = createWebOrderLineItems(basket);
                const selectedBillingAddress = address.find((item) => item.addressType === 'BILL' && item.isDefault === true);
                const selectedShippingAddress = address.find((item) => item.addressType === 'SHIP' && item.isDefault === true);
                this.props.createOrderStart({
                    billingAddressId: selectedBillingAddress.clientAddressId,
                    brandId: BRAND_ID,
                    discountCode: validateDiscount.discountCode || "",
                    orderId: null,
                    country: COUNTRY,
                    orderStatus: ORDER_STATUS.PEN,
                    shippingAddressId: selectedShippingAddress.clientAddressId,
                    storeCode,
                    webOrderLineItems,
                    browserId: decryptCryptr("browserId"),
                });
            }
            this.setState({ showCurrentProduct: false })
            return setTimeout(this.props.handleAddedToCart(), 1000);
        }
    };

    handleChangeQuantity = (basketName, parentID, size, setAsQuantity) => {
        const { basket } = this.props;
        if (basket["store"] && basket["store"]["StoreInfo"]) {
            this.setState({ quantityQTY: size.size.quantity, productQTY: `${parentID}/${size.size.productId}` });
        } else {
            this.props.addtocartStart(basketName, parentID, { ...size }, setAsQuantity);
        }
    }

    onMouseEnterHandler = (count) => {
        this.setState({
            stockCount: count,
            hover: true
        })
    }

    onMouseLeaveHandler = () => {
        this.setState({
            stockCount: 0,
            hover: false
        })
    }

    render() {
        const { locationSearchData, userStore, textBtn, product, sizes, selectedSize, selectedQuantity, selectQuantity, selectSize, isPayment, basket, from, isHourVisible = true } = this.props;
        const { showStoreCreated, quantityQTY, productQTY, isEmptyResponse, selectedProduct, showCurrentProduct } = this.state;
        const ids = productQTY.split("/");
        const currentSizes = sizes ? sizes.map(item => ({ value: item.productReference, text: item.size })) : [];
        let locationData = locationSearchData && locationSearchData.length
            ? STORE_DEPOT === "1"
                ? locationSearchData.filter(location => location.consignment)
                : !isPayment && !(product && Object.keys(product).length)
                    ? locationSearchData
                    : STORE_DELIVERY === "1"
                        ? locationSearchData.filter(location => location.hasBasket)
                        : locationSearchData
            : [];
        locationData = from === 'retour' ? locationData.filter(location => location.consignment) : locationData;
        const isConsignment = STORE_DEPOT === "1" && !locationData.length && locationSearchData.length;
        const products = getbasketLineItems();
        products.map(prod => prod.productId === Number(ids[1]) ? prod.quantity = quantityQTY : prod);
        const basketLineItems = isPayment ? [...products] : product && Object.keys(product).length ? [{
            productId: selectedProduct.productId,
            quantity: +selectedQuantity || 1
        }, ...products] : 0;
        const webInvertory = selectedProduct && selectedProduct.webInventory;
        const options = [...Productoptions(STORE_DELIVERY === "1" ? PRODUCT_MAX_QUANTITY : webInvertory || undefined), { key: 0, value: "0", text: i18next.t("product.product.5") }];
        return (
            <div className="storeLocator">
                <BlackWindow type="page" handleClick={this.props.toggleStoreLocator} />
                <div className="storeLocator-content container">
                    <div className="storeLocator-content-head">
                        <h4 className="storeLocator-content-head-title">{i18next.t('locator.3')}</h4>
                        <img src={closeIcon} className="x" onClick={this.props.toggleStoreLocator} alt="close" />
                    </div>
                    <div className="storeLocator-wrapper">
                        <div className="basket">
                            <div className="product-content">
                                {!isPayment && product && Object.keys(product).length ? (
                                    <div className="summary">
                                        {
                                            showCurrentProduct && (
                                                <>
                                                    <div className="summary-header dflex align-items-center">
                                                        <h4 className="title">{i18next.t('product.basket.summary.1')}</h4>
                                                        <h4 className="title">{i18next.t('product.basket.summary.2')}</h4>
                                                        <h4 className="title">{i18next.t("product.product.17")}</h4>
                                                        <h4 className="title">{i18next.t('product.basket.summary.4')}</h4>
                                                    </div>
                                                    <div className="summary-item dflex align-items-center">
                                                        <div className="summary-item-about dflex align-items-stretch store-locator-basket">
                                                            <div className="summary-item-img">
                                                                <Photo src={product.photo} thumbnail={product.thumbnail}
                                                                    discountDetail={""} />
                                                            </div>

                                                            <div
                                                                className="summary-item-about-content ">
                                                                <h4 className="title">{product.name}</h4>
                                                            </div>
                                                        </div>

                                                        <div className="dflex align-items-center summary-box summary-box-dropdown flex-column summary-box-qty-dropdown">
                                                            <Dropdown
                                                                className="mainSelect"
                                                                selection
                                                                value={selectedQuantity || 1}
                                                                options={options}
                                                                onChange={(e, { value }) => selectQuantity(value)}
                                                                icon={<Icon name='angle down' />}
                                                            />
                                                            {
                                                                this.state.hover && this.state.stockCount >= 1 && this.state.stockCount < 5 && STOCK_LEFT === "1" && STORE_DELIVERY === "1"
                                                                    ?
                                                                    <div
                                                                        className="product-item-count">{i18next.t('product.product.23', { count: this.state.stockCount })}</div>
                                                                    :
                                                                    ""
                                                            }
                                                        </div>
                                                        <div className="dflex align-items-center summary-box summary-box-dropdown summary-box-dropdown-price">
                                                            <Dropdown
                                                                className="mainSelect"
                                                                selection
                                                                value={selectedSize || (currentSizes[0] && currentSizes[0].value)}
                                                                options={currentSizes}
                                                                onChange={(e, { value, text }) => selectedSize === value ? null : selectSize(text, value)}
                                                                icon={<Icon name='angle down' />}
                                                            />
                                                        </div>
                                                        <div className="summary-box summary-box-price">
                                                            <p className="text">{selectedQuantity < 1 ? i18next.t('catalogue.product.3') : ""}</p>
                                                            {
                                                                Array.isArray(sizes) && sizes.length ?
                                                                    <Price
                                                                        startP={selectedSize === "" ? product.startingPrice : selectedProduct.startingPrice}
                                                                        discount={selectedSize === "" ? product.discountDetail : selectedProduct.discountDetail}
                                                                        newP={selectedSize === "" ? product.listPriceAfterDiscount : selectedProduct.listPriceAfterDiscount}
                                                                        quantity={selectedQuantity < 1 ? 1 : selectedQuantity}
                                                                    />
                                                                    :
                                                                    ""
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }

                                        {/* <Summary items={basket} changeQuantity={this.handleChangeQuantity}
                                                 removeItem={this.props.removeBasketStart} quantityQTY={quantityQTY}
                                                 productQTY={productQTY}/> */}
                                    </div>
                                ) : (
                                    <Summary
                                        items={product}
                                        changeQuantity={this.handleChangeQuantity}
                                        removeItem={this.props.removeBasketStart}
                                        quantityQTY={quantityQTY}
                                        productQTY={productQTY}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="storeLocator-wrapper-head">
                            <h4 className="storeLocator-title">{i18next.t('locator.6')}</h4>
                            <div className="search-content">
                                <div className="dflex justify-content-between">
                                    <LocationSearchInput
                                        classN="search-btn"
                                        showButton={true}
                                        basketLineItems={basketLineItems}
                                        shippingCity={false}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="storeLocator-wrapper-body">
                            <h4 className="storeLocator-title">
                                {isEmptyResponse ? i18next.t('locator.16')
                                    :
                                    STORE_DEPOT === "0" && STORE_DELIVERY === "0"
                                        ?
                                        i18next.t('locator.1')
                                        :
                                        isConsignment ?
                                            i18next.t('locator.16')
                                            :
                                            STORE_DEPOT === "1" ?
                                                i18next.t('locator.15') :
                                                !isPayment && !(product && Object.keys(product).length) ?
                                                    i18next.t('locator.7')
                                                    :
                                                    locationSearchData && locationSearchData.length ?
                                                        locationData && locationData.length ?
                                                            i18next.t('locator.7')
                                                            :
                                                            i18next.t('locator.11')
                                                        :
                                                        i18next.t('locator.7')
                                }
                            </h4>

                            {
                                locationData && locationData.length ? locationData.map((item) => {
                                    if ((item.hasBasket && (products && products.length))) {
                                        return <div className="storeLocator-wrapper-item" key={item.storeId}>
                                            <div className="storeLocator-wrapper-item-content">
                                                <h4 className="storeLocator-wrapper-item-content-title">
                                                    {item.name}
                                                </h4>
                                                <div
                                                    className="dflex align-items-start flex-wrap storeLocator-wrapper-item-content-block">
                                                    <div>
                                                        <p>{item.address}</p>
                                                        <p>{item.zip} {item.city}</p>
                                                        <p>Tél: {item.phone}</p>
                                                        {STORE_DELIVERY === "1" || STORE_DEPOT === "1" || STORE_DELIVERY === "0" ?
                                                            (isPayment || (product && Object.keys(product).length)) && STORE_DEPOT !== "1"
                                                                ?
                                                                <div className="desctopSize">
                                                                    <div
                                                                        className="location-adres-item-group-btn">
                                                                        <button
                                                                            className="location-adres-item-btn"
                                                                            onMouseEnter={() => this.onMouseEnterHandler(item.barcodeList[0].stock)}
                                                                            onMouseLeave={this.onMouseLeaveHandler}
                                                                            onClick={() => this.selectLocationDilevery(item, "STO")}>
                                                                            {
                                                                                textBtn ? textBtn :
                                                                                    item.hasBasket === false ?
                                                                                        `${i18next.t('locator.1')} ${dateStringFormat("threeDaysAfter")} ${isHourVisible ? '' : ''}` :
                                                                                        `${i18next.t('locator.1')} ${dateStringFormat("tomorrow")} ${isHourVisible ? '' : ''}`
                                                                            }
                                                                        </button>
                                                                        {/* <button
                                                                            className="location-adres-item-btn"
                                                                            onMouseEnter={() => this.onMouseEnterHandler(item.barcodeList[0].stock)}
                                                                            onMouseLeave={this.onMouseLeaveHandler}
                                                                            onClick={() => this.selectLocationDilevery(item, "DIS")}
                                                                        >
                                                                            {
                                                                                textBtn ? textBtn : `${i18next.t('locator.10')} ${dateStringFormat("", addDays(new Date(), DISPATCH_TIME))}`
                                                                            }
                                                                        </button> */}
                                                                    </div>
                                                                    {item.storeId === userStore.storeId && showStoreCreated &&
                                                                        <p id="selected-store">{i18next.t('locator.5')}</p>}
                                                                </div> :
                                                                <div
                                                                    className="align-items-start justify-content-start dflex flex-box">
                                                                    {!this.state.isMobile && <button
                                                                        className="location-adres-item-btn oneBtn"
                                                                        onClick={() => this.selectLocationDilevery(item, STORE_DEPOT === "1" ? "DEP" : "")}
                                                                    >
                                                                        {
                                                                            textBtn ? textBtn
                                                                                : STORE_DEPOT === "1" ? i18next.t('locator.14') :
                                                                                    item.hasBasket === false ?
                                                                                        `${i18next.t('locator.1')} ${dateStringFormat("threeDaysAfter")} `
                                                                                        :
                                                                                        `${i18next.t('locator.1')} ${dateStringFormat("tomorrow")} `
                                                                        }
                                                                    </button>}
                                                                    {item.storeId === userStore.storeId && showStoreCreated &&
                                                                        <div
                                                                            id="selected-store">{i18next.t('locator.5')}</div>}
                                                                </div> : ""
                                                        }
                                                    </div>

                                                    <div className="timeZoneBox">
                                                        {
                                                            getOpeningHours(item.openingHours) &&
                                                                getOpeningHours(item.openingHours).length ?
                                                                getOpeningHours(item.openingHours).map((hours, i) => {
                                                                    return (
                                                                        <div className='TimeBox' key={i}>
                                                                            <div>{` ${i18next.t(`weekDays.${i}`)}:`}</div>
                                                                            <div>{`${formatOpeningHours(hours)}`}</div>
                                                                        </div>
                                                                    )
                                                                }) : ""
                                                        }
                                                    </div>
                                                    {this.state.isMobile && <button
                                                        className="location-adres-item-btn oneBtn"
                                                        onClick={() => this.selectLocationDilevery(item, STORE_DEPOT === "1" ? "DEP" : "")}
                                                    >
                                                        {
                                                            textBtn ? textBtn
                                                                : STORE_DEPOT === "1" ? i18next.t('locator.14') :
                                                                    item.hasBasket === false ?
                                                                        `${i18next.t('locator.1')} ${dateStringFormat("threeDaysAfter")} `
                                                                        :
                                                                        `${i18next.t('locator.1')} ${dateStringFormat("tomorrow")} `
                                                        }
                                                    </button>}
                                                    <div className="mobileSize">
                                                        {STORE_DELIVERY === "1" ?
                                                            (isPayment || (product && Object.keys(product).length)) ?
                                                                <div>
                                                                    <div
                                                                        className="location-adres-item-group-btn">
                                                                        {/* <button
                                                                            className="location-adres-item-btn"
                                                                            onClick={() => this.selectLocationDilevery(item, "STO")}>
                                                                            {
                                                                                textBtn ? textBtn :
                                                                                    item.hasBasket === false ?
                                                                                        `${i18next.t('locator.1')} ${dateStringFormat("threeDaysAfter")} ` :
                                                                                        `${i18next.t('locator.1')} ${dateStringFormat("tomorrow")} `
                                                                            }
                                                                        </button> */}
                                                                        {/* <button
                                                                            className="location-adres-item-btn"
                                                                            onClick={() => this.selectLocationDilevery(item, "DIS")}>
                                                                            {
                                                                                textBtn ? textBtn : `${i18next.t('locator.10')} ${dateStringFormat("", addDays(new Date(), DISPATCH_TIME))}`
                                                                            }
                                                                        </button> */}
                                                                    </div>
                                                                    {item.storeId === userStore.storeId && showStoreCreated &&
                                                                        <p id="selected-store">{i18next.t('locator.5')}</p>}
                                                                </div> :
                                                                <div
                                                                    className="align-items-start justify-content-start dflex flex-box">
                                                                    {item.storeId === userStore.storeId && showStoreCreated &&
                                                                        <div className="w-100"
                                                                            id="selected-store">{i18next.t('locator.5')}</div>}
                                                                </div> : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    } else if (!(products && products.length)) {
                                        return (
                                            <div className="storeLocator-wrapper-item" key={item.storeId}>
                                                <div className="storeLocator-wrapper-item-content">
                                                    <h4 className="storeLocator-wrapper-item-content-title">
                                                        {item.name}
                                                    </h4>
                                                    <div
                                                        className="dflex align-items-start flex-wrap storeLocator-wrapper-item-content-block">
                                                        <div>
                                                            <p>{item.address}</p>
                                                            <p>{item.zip} {item.city}</p>
                                                            <p>Tél: {item.phone}</p>
                                                            {STORE_DELIVERY === "1" || STORE_DEPOT === "1" || STORE_DELIVERY === "0" ?
                                                                (isPayment || (product && Object.keys(product).length)) && STORE_DEPOT !== "1"
                                                                    ?
                                                                    <div className="desctopSize">
                                                                        <div
                                                                            className="location-adres-item-group-btn">
                                                                            <button
                                                                                className="location-adres-item-btn"
                                                                                onMouseEnter={() => this.onMouseEnterHandler(item.barcodeList[0].stock)}
                                                                                onMouseLeave={this.onMouseLeaveHandler}
                                                                                onClick={() => this.selectLocationDilevery(item, "STO")}>
                                                                                {
                                                                                    textBtn ? textBtn :
                                                                                        item.hasBasket === false ?
                                                                                            `${i18next.t('locator.1')} ${dateStringFormat("threeDaysAfter")} ${isHourVisible ? '' : ''}` :
                                                                                            `${i18next.t('locator.1')} ${dateStringFormat("tomorrow")} ${isHourVisible ? '' : ''}`
                                                                                }
                                                                            </button>
                                                                            {/* <button
                                                                            className="location-adres-item-btn"
                                                                            onMouseEnter={() => this.onMouseEnterHandler(item.barcodeList[0].stock)}
                                                                            onMouseLeave={this.onMouseLeaveHandler}
                                                                            onClick={() => this.selectLocationDilevery(item, "DIS")}
                                                                        >
                                                                            {
                                                                                textBtn ? textBtn : `${i18next.t('locator.10')} ${dateStringFormat("", addDays(new Date(), DISPATCH_TIME))}`
                                                                            }
                                                                        </button> */}
                                                                        </div>
                                                                        {item.storeId === userStore.storeId && showStoreCreated &&
                                                                            <p id="selected-store">{i18next.t('locator.5')}</p>}
                                                                    </div> :
                                                                    <div
                                                                        className="align-items-start justify-content-start dflex flex-box">
                                                                        {item.storeId === userStore.storeId && showStoreCreated &&
                                                                            <div
                                                                                id="selected-store">{i18next.t('locator.5')}</div>}
                                                                    </div> : ""
                                                            }
                                                        </div>
                                                        <div className="timeZoneBox">
                                                            {
                                                                getOpeningHours(item.openingHours) &&
                                                                    getOpeningHours(item.openingHours).length ?
                                                                    getOpeningHours(item.openingHours).map((hours, i) => {
                                                                        return (
                                                                            <div className='TimeBox' key={i}>
                                                                                <div>{` ${i18next.t(`weekDays.${i}`)}:`}</div>
                                                                                <div>{`${formatOpeningHours(hours)}`}</div>
                                                                            </div>
                                                                        )
                                                                    }) : ""
                                                            }
                                                        </div>
                                                        <div className="mobileSize">
                                                            {STORE_DELIVERY === "1" ?
                                                                (isPayment || (product && Object.keys(product).length)) ?
                                                                    <div>
                                                                        <div
                                                                            className="location-adres-item-group-btn">
                                                                            <button
                                                                                className="location-adres-item-btn"
                                                                                onClick={() => this.selectLocationDilevery(item, "STO")}>
                                                                                {
                                                                                    textBtn ? textBtn :
                                                                                        item.hasBasket === false ?
                                                                                            `${i18next.t('locator.1')} ${dateStringFormat("threeDaysAfter")} ` :
                                                                                            `${i18next.t('locator.1')} ${dateStringFormat("tomorrow")} `
                                                                                }
                                                                            </button>
                                                                            {/* <button
                                                                            className="location-adres-item-btn"
                                                                            onClick={() => this.selectLocationDilevery(item, "DIS")}>
                                                                            {
                                                                                textBtn ? textBtn : `${i18next.t('locator.10')} ${dateStringFormat("", addDays(new Date(), DISPATCH_TIME))}`
                                                                            }
                                                                        </button> */}
                                                                        </div>
                                                                        {item.storeId === userStore.storeId && showStoreCreated &&
                                                                            <p id="selected-store">{i18next.t('locator.5')}</p>}
                                                                    </div> :
                                                                    <div
                                                                        className="align-items-start justify-content-start dflex flex-box">
                                                                        {item.storeId === userStore.storeId && showStoreCreated &&
                                                                            <div className="w-100"
                                                                                id="selected-store">{i18next.t('locator.5')}</div>}
                                                                    </div> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                }
                                ) : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state: any): Props & typeof StoreLocationActionCreators => {
    return {
        locationSearchData: getLocationSearch(state),
        userStore: getUserStore(state),
        basket: getBasket(state),
        loading: getClickAndCollectLoading(state),
        address: getMesAddress(state),
    }
};
const mapDispatchToProps = (dispatch): typeof StoreLocationActionCreators => {
    return bindActionCreators({ ...StoreLocationActionCreators, ...OrderActionCreators, ...MesAddressActionCreators }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreLocator);
