import React from "react";
import '../product.scss'
import ProductInfo from "./productInfo";
import { connect } from "react-redux";
import Complect from "./complect";
import Details from "./details";
import { getConvertedItemDataForGA4 } from "../../../../helpers/helpers";
import { TrackAddToCartComplect, TrackItemview, TrackAddToCart, TrackItemSelect } from "../../../../helpers/ga4Tracker";
import { getBasket } from "../../../addToCart/redux/selectors";
import { getMenu } from "../../../../layouts/main/header/redux/selectors";
import { getIsSentByColor } from "../../redux/selectors";
import { ProductActionCreators } from "../../redux/actions";
import { bindActionCreators } from "redux";

interface Props {
    handleBasketToggle?: any,
    data: any,
    recommendedProducts: any,
    handelResetLocationSearch: () => void,
    locationSearchDestroyedStart: () => void;
    menuItem?: any,
    basket: any,
    menu?: any,
    isSentByColor?: boolean,
    selectColor?: any,
}

interface State { }

class ProductS extends React.Component<Props> {
    state: State = {};

    private divRef = React.createRef<HTMLDivElement>();
    public refDescription = React.createRef<HTMLDivElement>();

    scrollToDescription = () => {
        window.scrollTo(0, this.refDescription.current!.offsetTop - this.refDescription.current!.offsetHeight);
    }
    scrollTocompositeDataRef = (event) => {
        event.stopPropagation()
        window.scrollTo(0, this.divRef.current!.offsetTop - 150);
    }

    componentDidUpdate(prevProps) {
        if (this.props.data
            && prevProps.data
            && this.props.data.productId !== prevProps.data.productId) {
            const data = getConvertedItemDataForGA4(this.props.menuItem, this.props.data, 1, undefined);
            const value = data.price;
            if (this.props.isSentByColor) {
                TrackItemSelect(data, value)
                this.props.selectColor(false)
            }
            else TrackItemview(data, value)
        }
    }

    componentDidMount() {
        const data = getConvertedItemDataForGA4(this.props.menuItem, this.props.data, 1, undefined);
        const value = data.price;
        TrackItemview(data, value)
    }

    handleAddToCartEvent = (isComplect: boolean = false, changedSize: any = null, selectedQuantity: any = null, items: any = null) => {

        const { data, menuItem } = this.props;
        if (isComplect) {
            const itemsT = [] as any
            let value = 0
            Object.keys(items).map((item: any) => {
                const dataT = getConvertedItemDataForGA4(this.props.menuItem, items[item],
                    items[item].size && items[item].size.quantity
                    && items[item].size.quantity > 0
                        ? items[item].size.quantity
                        : 1,
                    items[item].size);
                itemsT.push(dataT)
                let price = dataT.price * dataT.quantity
                value += price
            })
            return TrackAddToCartComplect(itemsT, value)
        }
        const dataT = getConvertedItemDataForGA4(menuItem, data, +selectedQuantity && +selectedQuantity > 0 ? +selectedQuantity : 1, changedSize);
        const value = dataT.price * dataT.quantity
        TrackAddToCart(dataT, value)
    }

    componentWillUnmount(): void {
        this.props.locationSearchDestroyedStart();
    }

    render() {
        const { data, recommendedProducts, menuItem } = this.props;
        const compositeData = data && Object.keys(data).length && data.compositeData;
        return (
            <div className="products">
                <ProductInfo handleBasketToggle={this.props.handleBasketToggle} data={data}
                    handleScrollToDescription={this.scrollToDescription}
                    handleScrollTocompositeData={this.scrollTocompositeDataRef}
                    recommendedProducts={recommendedProducts}
                    menuItem={menuItem}
                    handleAddToCartEvent={this.handleAddToCartEvent}
                    handelResetLocationSearch={this.props.handelResetLocationSearch} />

                <div>
                    {data.compositeData && Object.keys(data.compositeData).length ?
                        <div ref={this.divRef}>
                            <Complect handleBasketToggle={this.props.handleBasketToggle} data={data}
                                handleScrollToDescription={this.scrollToDescription}
                                menuItem={menuItem}
                                handleAddToCartEvent={this.handleAddToCartEvent}
                            />
                            <div className="pure-complect-composite">
                                <div className="product-container">
                                    <div className="pure-complect-content">
                                        <div className="pure-complect-about">
                                            <h4>{data.compositeData.name}</h4>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: data.compositeData.description }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""
                    }
                </div>
                <Details refDescription={this.refDescription}
                    data={data} />
            </div>
        )
    }
}

const mapStateToProps = (state: any): any => ({
    basket: getBasket(state),
    menu: getMenu(state),
    isSentByColor: getIsSentByColor(state),
});

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(ProductActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductS);


