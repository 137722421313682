import {createActions} from 'reduxsauce';

export const {Types: ProductTypes, Creators: ProductActionCreators} = createActions ({
    productStart: ['data', 'callback'],
    productSuccess: ['product'],
    productFailure: ['error'],
    productReset: [null],
    careStart: ['productId'],
    careSuccess: ['data'],
    careFailure: ['error'],
    compositionStart: ['productId'],
    compositionSuccess: ['data'],
    compositionFailure: ['error'],
    productCompositeStart: ['id'],
    productCompositeSuccess: ['data'],
    productCompositeFailure: ['error'],
    recommendedProductsStart: [null],
    locationSearchDestroyedStart: [null],
    selectColor: ['data'],
});
