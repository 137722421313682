import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import Checkbox from '../../common/chckboxList/checkboxList';
import i18next from "i18next";
import { getSuccess, getLoading } from "../redux/selectors";
import { ICreationState } from "../redux/reducers";
import { CreationActionCreators } from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, Input } from 'semantic-ui-react';
import { BRAND_ID } from "../../../utils/constants/variables"
import "./creation.scss";
import { getRouteByMenuKey, getApiPrefix, } from "../../../helpers/helpers";
import { getMenu } from "../../connection/redux/selectors";
import { MetroSpinner } from "react-spinners-kit";
import { TrackSignup } from '../../../helpers/ga4Tracker';
import { getBrandDetail } from '../../../layouts/main/header/redux/selectors';

interface Props extends ICreationState {
    emailToRegister?: string,
    loading: boolean
}

interface CreationState {
    creation: any,
    menuList: any,
    loading: boolean
    brandDetails: any
}

interface State {
    show: boolean,
    form: any,
    error: boolean,
    isRedirect: boolean,
    disabled: boolean,
    success: boolean,
    validation: any
    inputErrors: any
}

class Creation extends Component<Props & typeof CreationActionCreators, State, CreationState> {
    state: State = {
        show: false,
        disabled: true,
        success: false,
        form: {
            username: '',
            userlastname: '',
            email: this.props.emailToRegister || '',
            password: '',
            preferredStoreId: '',
            connection_1: false,
            connection_2: false,
            connection_3: true,
            error: false,
            brandId: BRAND_ID,
            language: getApiPrefix(),
            birthdate: '',
            gender: 'M',
            day: '',
            month: '',
            year: '',
            webSiteUrl: this.props.brandDetails.name || '',
            storeCode: this.props.brandDetails.storeCode || ''
        },
        error: false,
        inputErrors: { username: '', userlastname: '', password: '' },
        isRedirect: false,
        validation: {
            day: false,
            month: false,
            year: false,
        }
    };
    private interval;
    componentDidUpdate(prevProps, prevState) {
        if (this.props.creation.created && this.props.creation.created !== prevProps.creation.created) {
            this.setState({ success: true })
        }
        if (!prevState.success && this.state.success) {

            this.interval = setInterval(() => {
                this.setState({ isRedirect: true });

            }, 3000);
        }

    }



    componentWillUnmount() {
        clearInterval(this.interval);
    }
    handleUserRegistration = (e) => {
        const { form } = this.state;
        const { day, month, year } = form;
        const birthdate = day && month ? `${year || 1900}-${month}-${day}` : "";
        this.setState((prevState: State) => ({
            form: {
                ...prevState.form,
                birthdate
            }
        }), () => this.props.creationStart(this.state.form));

        TrackSignup()
        this.setState({ error: false });
        e.preventDefault();
    };

    showPass = () => {
        const { show } = this.state;
        this.setState({
            show: !show
        })
    };


    handleChange = (e, { name, value, checked }) => {
        if (checked !== undefined && name === "newsOptin") {
            value = checked ? 'Y' : 'N'
        }
        var form = { ...this.state.form };
        form[name] = value;
        this.setState({ form }, () => this.checkForm());
    };

    handleInputChange = (e, { name, value }) => {

        if (name === "day") {
            if (value.length > 2) {
                return
            }
            const valid = /[0-9]{2}/.test(value);
            if (value.length && (!valid || parseInt(value) < parseInt("01") || parseInt(value) > parseInt("31"))) {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        day: true
                    }
                }))
            } else {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        day: false
                    }
                }))
            }
            if (value.length > 2) {
                return
            }
        }

        if (name === "month") {
            if (value.length > 2) {
                return
            }
            const valid = /[0-9]{2}/.test(value);
            if (value.length && (!valid || parseInt(value) < parseInt("01") || parseInt(value) > parseInt("12"))) {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        month: true
                    }
                }))
            } else {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        month: false
                    }
                }))
            }
        }
        if (name === "year") {
            if (value.length > 4) {
                return
            }
            const valid = /[0-9]{4}/.test(value);
            if (value.length && (!valid || parseInt(value) < parseInt("1900") || parseInt(value) > new Date().getFullYear())) {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        year: true
                    }
                }))
            } else {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        year: false
                    }
                }))
            }
        }

        this.setState(() => ({
            form: {
                ...this.state.form,
                [name]: value
            }
        }))
    };

    handleChangeSelect = (name = "", value: any = "") => {
        this.setState((prevState: State) => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }), () => this.checkForm())
    };

    handleChangeRadioButton = (e) => {
        const { name, value } = e.target;
        this.setState((prevState: State) => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }), () => this.checkForm())
    };

    handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        this.setState((prevState: State) => ({
            form: {
                ...prevState.form,
                [name]: checked
            }
        }), () => this.checkForm())
    };

    checkForm = () => {
        const { username, userlastname, email, password, gender, connection_2 } = this.state.form;
        if (username && username.length < 2) {

            this.setState((prevState) => {
                return {
                    ...prevState,
                    inputErrors: { ...prevState.inputErrors, username: true }
                }
            })
        } else {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    inputErrors: { ...prevState.inputErrors, username: false }
                }
            })
        }
        if (userlastname && userlastname.length < 2) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    inputErrors: { ...prevState.inputErrors, userlastname: true }
                }
            })
        } else {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    inputErrors: { ...prevState.inputErrors, userlastname: false }
                }
            })
        }
        if (password && password.length < 4) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    inputErrors: { ...prevState.inputErrors, password: true }
                }
            })
        } else {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    inputErrors: { ...prevState.inputErrors, password: false }
                }
            })
        }
        username && userlastname && email && password && gender && connection_2 ?
            this.setState({ disabled: false }) : this.setState({ disabled: true })
    };

    render(): React.ReactNode {
        const showClass = this.state.show ? "show" : "";
        const { form, disabled, validation, success, isRedirect, inputErrors } = this.state;
        const { creation, favoriteStore, menuList, loading } = this.props;
        if (success && creation.created) {
            return (
                <div className="connection">
                    {isRedirect && <Redirect push to={"/"} />}
                    {/* {isRedirect && window.location.reload()} */}
                    <h4 className="massegBox">{`${i18next.t("signup.successMessage")} ${creation.email}`}</h4>
                </div>
            )
        }
        const options = favoriteStore && Object.keys(favoriteStore).length ? favoriteStore.filter(item => ({ "value": item.storeId, "text": `${item.name} ${item.zip}` })) : []
        const isButtonDisabled = disabled
        || (form.day ? validation.day : true)
        || (form.month ? validation.month : true)
        || (form.year ? validation.year : true)

        return (
            <div className="connection">
                <h4 className="title">{i18next.t("signup.title")}</h4>
                <a className="signup-link"
                    onClick={() => this.props.clickPopUpStart()}>{i18next.t("signup.link")}</a>
                <Form onSubmit={this.handleUserRegistration}>
                    <div className="connection-content">
                        <div className="connection-gender">
                            <h4 className="input-label">{i18next.t("signup.sex.civil")}</h4>

                            <div className="radio-button-box">
                                <label htmlFor="men">
                                    <input type="radio" name="gender" id="men" value='M'
                                        onChange={this.handleChangeRadioButton} checked={form.gender === 'M'} />
                                    <span></span>
                                    {i18next.t("signup.sex.male")}
                                </label>
                            </div>

                            <div className="radio-button-box">
                                <label htmlFor="women">
                                    <input type="radio" name="gender" id="women" value='F'
                                        onChange={this.handleChangeRadioButton} checked={form.gender === 'F'} />
                                    <span></span>
                                    {i18next.t("signup.sex.female")}
                                </label>
                            </div>
                        </div>
                        <div className="user-name-content">
                            <div className="user-name">
                                <label htmlFor="user-name"
                                    className="input-label">{i18next.t("signup.info.name")}</label>
                                <Form.Field
                                    error={inputErrors.userlastname}
                                    required control={Input} type="text" name="userlastname" id="user-lastname"
                                    value={form.userlastname}
                                    placeholder={i18next.t("signup.info.name")}
                                    onChange={this.handleChange}
                                    className="input-box" />
                                {inputErrors.userlastname && <div className="error-text input-error-text">{i18next.t("signup.inputErrors.lastname")}</div>}
                            </div>

                            <div className="user-lastname">
                                <label htmlFor="user-lastname"
                                    className="input-label">{i18next.t("signup.info.surname")}</label>
                                <Form.Field
                                    error={inputErrors.username}
                                    required control={Input} type="text" name="username" id="user-name"
                                    value={form.username}
                                    placeholder={i18next.t("signup.info.surname")}
                                    onChange={this.handleChange}
                                    className="input-box" />
                                {inputErrors.username && <div className="error-text input-error-text">{i18next.t("signup.inputErrors.username")}</div>}
                            </div>
                        </div>
                        <div className="email-adress">
                            <label htmlFor="email" className="input-label">{i18next.t("signup.info.email")}</label>
                            <Form.Field
                                required
                                control={Input} type="email"
                                placeholder={i18next.t("signIn.email")} name="email" disabled
                                value={form.email}
                                onChange={this.handleChange} className="input-box" />
                        </div>
                        <div className="password-content">
                            <label htmlFor="password"
                                className="input-label">{i18next.t("signup.info.pass")}</label>
                            <Form.Field
                                error={inputErrors.password}
                                required control={Input}
                                type={this.state.show ? "text" : "password"} name="password" value={form.password}
                                placeholder={i18next.t("signIn.motDePasse")}
                                onChange={this.handleChange}
                                className="input-box" />
                            <span className={`icon-pass ${showClass}`} onClick={() => {
                                this.showPass()
                            }}>
                                <Icon name="eye"></Icon>
                            </span>
                            {inputErrors.password && <div className="error-text input-error-text">{i18next.t("signup.inputErrors.password")}</div>}
                        </div>

                        <div className="data-picker">
                            <div>
                                <div className="input-label">{i18next.t("signup.info.date")}</div>
                                <div className="date-naissance input-box">
                                    <Form.Field
                                        error={validation.day}
                                        control={Input}
                                        className="input-style"
                                        placeholder={i18next.t("signup.date.day")}
                                        name="day"
                                        value={form.day}
                                        onChange={this.handleInputChange}
                                    />
                                    <span>/</span>
                                    <Form.Field
                                        error={validation.month}
                                        control={Input}
                                        className="input-style"
                                        placeholder={i18next.t("signup.date.month")}
                                        name="month"
                                        value={form.month}
                                        onChange={this.handleInputChange}
                                    />
                                    <span>/</span>
                                    <Form.Field
                                        // error={validation.year}
                                        control={Input}
                                        type="text"
                                        className="input-style"
                                        placeholder={i18next.t("signup.date.year")}
                                        name="year"
                                        value={form.year}
                                        onChange={this.handleInputChange}
                                    />
                                    <Icon name="calendar alternate outline" />
                                </div>
                                {
                                    validation.day || validation.month || validation.year ?
                                        <div className="error-text">{i18next.t("date.1")}</div> : ""
                                }
                            </div>

                            <div className="data-picker-select">
                                <div className="input-label">{i18next.t("signup.info.favoriteStore")}</div>
                                <Dropdown
                                    className="mainSelect "
                                    value={form.preferredStoreId}
                                    options={options.reduce((newArray, item) => {
                                        if (item.storeType !== "G") {
                                            newArray.push({
                                                key: item.id,
                                                text: `${item.name}, ${item.address}, ${item.zip}, ${item.city}`.toUpperCase(),
                                                value: item.id,
                                            })
                                        }
                                        return newArray;
                                    }, [])}
                                    compact
                                    selection
                                    name={"preferredStoreId"}
                                    onChange={(e, { value, name }) => this.handleChangeSelect(name, value)}
                                    icon={<Icon name='angle down' />}
                                />

                            </div>
                        </div>

                        <Checkbox

                            text={i18next.t("signup.info.checkboxSecond")}
                            data={<Link to={getRouteByMenuKey(menuList, "donnees_personnelles").route}
                                target="_blank"  >{i18next.t('footer.mini.3')}</Link>}
                            name="connection_2" checked={form.connection_2}
                            handleChange={this.handleChangeCheckbox}

                        />
                        <Checkbox
                            text={i18next.t("signup.info.checkboxFirst")}
                            name="connection_1"
                            checked={form.connection_1}
                            handleChange={this.handleChangeCheckbox} />
                        <Checkbox text={i18next.t("signup.info.checkboxThird")} name="connection_3"
                            checked={form.connection_3} handleChange={this.handleChangeCheckbox} />

                        {!loading ?
                            <button className={`main-btn ${isButtonDisabled ? "disabled" : ""}`} type="submit"
                                disabled={isButtonDisabled}>{i18next.t("signIn.title")}</button>
                            :
                            <button className="main-btn spinner-btn-wrapper">
                                <div className="metrospinner">
                                    <MetroSpinner size={20} />
                                </div>
                                <span className="spinner-btn-wrapper-text">{i18next.t("signIn.title")}</span>
                            </button>

                        }

                    </div>
                </Form>

            </div>
        )
    }
}


const mapStateToProps = (creationState: any): CreationState => ({
    creation: getSuccess(creationState),
    menuList: getMenu(creationState),
    loading: getLoading(creationState),
    brandDetails: getBrandDetail(creationState)

});

const mapDispatchToProps = (dispatch): typeof CreationActionCreators => {
    return bindActionCreators(CreationActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Creation);
