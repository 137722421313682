import React, { useEffect, useState } from "react";
import Input from '../../../common/input/Input'
import i18next from "i18next";
import BlackWindow from "../../../common/blackWindow";
import closeIcon from "./../../../../assets/img/close.svg";
import { capitalize, getFilterValue } from "../../../../helpers/helpers";
import { FOREIGN_DELIVERY, COUNTRY } from "../../../../utils/constants/variables";
import { Dropdown } from "semantic-ui-react";
import "./adressPopup.scss"


interface Props {
	handleToggleAddressPopup: any,
	handleUpsertAddressItem: any,
	item: any
}

export const AddressPopup: React.FC<Props> = ({
	handleToggleAddressPopup,
	handleUpsertAddressItem,
	item
}) => {
	const [itemState, setItemState] = useState<Record<string, any>>({ ...item });
	const [isZipValid, setIsZipValid] = useState<boolean>(true);
	const [validation, setValidation] = useState<Record<string, { required: boolean; invalid: boolean; }>>({
		zip: { required: true, invalid: false },
		name: { required: true, invalid: false },
		city: { required: true, invalid: false },
		phone: { required: true, invalid: false },
		country: { required: true, invalid: false },
		address1: { required: true, invalid: false },
	});

	const options = [
		{ "value": "Italy", "text": `${i18next.t('Countrylist.6')}` },
		{ "value": "France", "text": `${i18next.t('Countrylist.1')}` },
		{ "value": "Austria", "text": `${i18next.t('Countrylist.7')}` },
		{ "value": "Monaco", "text": `${i18next.t('Countrylist.5')}` },
		{ "value": "Germany", "text": `${i18next.t('Countrylist.8')}` },
		{ "value": "Switzerland", "text": `${i18next.t('Countrylist.3')}` },
		{ "value": "Belgium", "text": `${i18next.t('Countrylist.2')}` },
		{ "value": "Portugal", "text": `${i18next.t('Countrylist.9')}` },
		{ "value": "Netherlands", "text": `${i18next.t('Countrylist.10')}` },
		{ "value": "Luxembourg", "text": `${i18next.t('Countrylist.4')}` },
		{ "value": "Spain", "text": `${i18next.t('Countrylist.11')}` },
	];

	useEffect(() => {
		window.scroll(0, 0);
		if (!item.country) setItemState({ ...itemState, country: getDefaultCountry() })
	}, [])

	const getDefaultCountry = () => {
		switch (COUNTRY) {
			case "FR":
				return 'France';
			case "DE":
				return 'Germany';
			case "IT":
				return 'Italy';
			default:
				return 'France';
		};
	};

	const handleCountryChange = (name = "", value = "") => {
		setItemState({
			...itemState,
			[name]: value
		})
	};

	const handleChange = ({ target }) => {
		let { name, value } = target;

		value = (value || "").replace(/["?!;]/ig, "")

		if (name === 'zip') {
			const validZip = /^[0-9]*$/.test(value);
			value = value.replace(/\D/g, "");
			setIsZipValid(validZip);
		} else if (name === 'phone') {
			value = value.replace(/[^0-9"]/ig, "");
		} else {
			setIsZipValid(true);
		}

		if (
			(name === 'zip' && value.length <= 5)
			|| (name === 'city' && value.length <= 25)
			|| (name !== 'zip' && name !== 'city' && value.length <= 40)
		) {

			const isValid = (/"|''|^'|\s+'$|'$/g).test(value);
			setValidation({
				...validation,
				[name]: { ...validation[name], invalid: isValid }
			});
			setItemState({
				...itemState,
				[name]: getFilterValue(value)
			})
		}
	};

	const handleUpsertItem = () => {
		let hasInvalidElement = false;

		if (itemState.zip) {
			itemState.zip = String(itemState.zip || "").padStart(5, '0');
		}

		for (let key in validation) {
			if (!itemState[key]) {
				hasInvalidElement = true;
				validation[key].invalid = true;
			} else {
				validation[key].invalid = false;
			}
		}

		hasInvalidElement ? setValidation({ ...validation }) : handleUpsertAddressItem(itemState);
	};

	const handleChangeSelect = (_, target) => {
		const { value, name } = target;
		setValidation({
			...validation,
			[name]: { ...validation[name], invalid: false }
		})
		handleCountryChange(name, value)
	};


	return (
		<>
			<BlackWindow type="page" handleClick={(event) => handleToggleAddressPopup("", "", event)} />

			<div className="address-popup dflex justify-content-center align-items-center">
				<div className="address-popup-content">
					<div className="dflex justify-content-between align-items-center address-popup-content-title">
						<h4 className="title">{i18next.t('product.basket.address.1')}</h4>
						<img src={closeIcon}
							className="closePopUp"
							onClick={(event) => handleToggleAddressPopup("", "", event)}
							alt="close icon" />
					</div>
					<div className="address-popup-content-wrapper">
						<div className="address-popup-input-content dflex justify-content-between flex-wrap">
							<Input
								invalid={validation.name.invalid}
								id="address-popup-input-content-1"
								name="name"
								value={itemState.name || ""}
								placeholder={`${i18next.t('product.basket.address.2')} ${i18next.t('product.basket.address.3')}`}
								text={`${i18next.t('product.basket.address.2')}  *`}
								handleChange={handleChange}
							/>
						</div>
						<div className="address-popup-input-content dflex justify-content-between flex-wrap">
							<Input
								invalid={validation.phone.invalid}
								id="address-popup-input-content-3"
								name="phone"
								value={itemState.phone || ""}
								placeholder={i18next.t('product.basket.address.4')}
								text={`${i18next.t('product.basket.address.4')} *`}
								handleChange={handleChange}
							/>
						</div>

						<Input
							invalid={validation.address1.invalid}
							id="address-popup-input-content-4"
							name="address1"
							value={itemState.address1 || ""}
							placeholder={i18next.t('product.basket.address.5')}
							text={`${i18next.t('product.basket.address.5')} *`}
							handleChange={handleChange}

						/>
						<Input
							id="address-popup-input-content-5"
							name="address2"
							value={itemState.address2 || ""}
							placeholder={i18next.t('product.basket.address.6')}
							text={i18next.t('product.basket.address.6')}
							handleChange={handleChange}

						/>

						<div className="address-popup-input-content dflex justify-content-between flex-wrap">
							<Input
								invalid={validation.city.invalid}
								id="address-popup-input-content-2"
								name="city"
								value={itemState.city || ""}
								placeholder={i18next.t('product.basket.address.7')}
								text={`${i18next.t('product.basket.address.7')} *`}
								handleChange={handleChange}
							/>
							<Input
								invalid={validation.zip.invalid}
								id="address-popup-input-content-6"
								name="zip"
								value={itemState.zip || ""}
								placeholder={i18next.t('product.basket.address.8')}
								text={`${i18next.t('product.basket.address.8')} *`}
								handleChange={handleChange}
							/>
							{
								!isZipValid ? <p className="error-data">{i18next.t('date.1')}</p> : ""
							}
						</div>
						<div className="address-popup-input-content dflex justify-content-between flex-wrap">
							<div className="address-popup-input-content address-popup-select-content  dflex justify-content-between flex-wrap">
								<div className="address-wrrapper">
									<label
										className={validation.country.invalid ? "invalid" : ""}>{i18next.t('product.basket.address.9')}
										*</label>
									<Dropdown
										icon="angle down"
										compact
										selection
										options={options}
										value={itemState.country && capitalize(itemState.country)}
										name={`country`}
										onChange={handleChangeSelect}
										className={"mainSelector fontWeightNormal"}
									/>
								</div>
							</div>
							{
								FOREIGN_DELIVERY === "0" ?
									<div className="address-popup-input-content address-popup-select-content  dflex justify-content-between flex-wrap">
										<p>{i18next.t('payment.12')}</p>
									</div> : ""
							}

						</div>
						<button className="button-style  hoverButtonStyle"
							onClick={handleUpsertItem}>{i18next.t('product.basket.address.10')}</button>
					</div>
				</div>
			</div>
		</>
	);
}
