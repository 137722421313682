import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import i18next from "i18next";
import {MenuActionCreators} from "./../../redux/actions";
import {getLanguage, getMenu} from "../../redux/selectors";

import {getLang} from "./../../../../../helpers/helpers";
// Icons
import fr_flag from "./../../../../../assets/img/Language/fr.png"
import de_flag from "./../../../../../assets/img/Language/de.png"
import en_flag from "./../../../../../assets/img/Language/en.png"
import it_flag from "./../../../../../assets/img/Language/it.png"
//style
import "./Languege.scss"


interface State {
    showLanguage?: boolean;
    setLanguage: string
}
interface Props {
    menu: any,
    language: string
}
class Language extends Component<Props & typeof MenuActionCreators> {
    state : State= {
        showLanguage : false,
        setLanguage : "de",
    };

    componentDidMount(){
        this.setState({setLanguage: getLang()})
    }

    hendleShowLanguage = () => {
        this.setState({showLanguage: !this.state.showLanguage})
    }

    hendleChangeLanguage = (Lang) => {
        this.setState({setLanguage: Lang})
        i18next.changeLanguage(Lang);

        // const path = this.props.history.location.pathname
        // const url = path.replace(/\/[a-z]{2}\//, `/`)

        this.props.history.push(`/${Lang}`);

        this.props.languageStart(Lang);

        this.props.menuStart()
    }

    render(){
        const {showLanguage,setLanguage} = this.state

        return(
            <div className="language">
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    menu: getMenu(state),
    language: getLanguage(state),

});

const mapDispatchToProps = (dispatch): typeof MenuActionCreators => {
    return bindActionCreators({ ...MenuActionCreators}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Language));


