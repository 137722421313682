import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import i18next from "i18next";


import { MesCommandesActionCreators } from "../redux/actions";
import { getError, getStatus, getMesCommandesOrders, getLoading, getMesTicketsOrders } from "../redux/selectors";
import "./mesCommandes.scss";
import RetournerUnArticle from "../../../../../retour/container/component/retournerUnArticle/container";
import RecapCommande from "../../../../../recapCommande/container";
import { getDateTimestamp, getCurrency } from "../../../../../../helpers/helpers";
import { replaceComma, getRouteByMenuKey } from "../../../../../../helpers/helpers";
import Photo from "../../../../../common/product/photo";
import { ORDER_STATUS } from '../../../../../../utils/constants/variables';
import TicketDetails from '../../../../../ticketDetails/ticketDetails';
import { getMenu } from '../../../../redux/selectors';
import LogoLoader from "../../../../../../layouts/main/logoLoader";

interface Props {
    handleUpdateChild?: any,
    orders?: any,
    tickets: any,
    error?: string,
    status?: string,
    loading?: boolean,
    menuKey?: string,
    menuList: any[],
}

interface State {
    showChild: string,
    selectedOrder: any,
    retourRoute: string,
}

class MesCommandes extends Component<Props & typeof MesCommandesActionCreators, State> {
    state: State = {
        showChild: "",
        selectedOrder: {},
        retourRoute: ''
    };

    componentDidMount(): void {
        const { menuList } = this.props;
        window.scroll(0, 0);
        this.checkIsCommand()
            ? this.props.mesCommandesStart()
            : this.props.mesTicketsStart();
        const menu = getRouteByMenuKey(menuList, 'retour');
        this.setState({ retourRoute: menu.route });
    }

    componentWillReceiveProps(nextProps): void {
        const { showChild, selectedOrder } = this.state;
        if (showChild && selectedOrder && Object.keys(selectedOrder).length) {
            this.setState({ showChild: "", selectedOrder: {} });
        }
    }

    checkIsCommand = () => {
        const { menuKey = '' } = this.props;
        return menuKey === "mes_commandes" ? true : false;
    }

    toggleChild = (child, item) => {
        window.scroll(0, 0);
        this.setState({
            showChild: child,
            selectedOrder: item,
        })
    };

    render() {
        const { orders, loading, tickets } = this.props;
        const { showChild, selectedOrder } = this.state;
        const isCommand = this.checkIsCommand()
        const data = isCommand ? orders : tickets;

        return (
            <div>
                {
                    !showChild ?
                        <div className="mes-commandes">
                            <div className="container">
                                <div className="mes-commandes-head">
                                    {
                                        loading ? <LogoLoader />
                                            :
                                            data && data.length ?
                                                <span>{data.length} {
                                                    isCommand
                                                        ? i18next.t('commandes.4')
                                                        : i18next.t('commandes.10')
                                                }</span>
                                                :
                                                <span> {
                                                    isCommand
                                                        ? i18next.t('commandes.9')
                                                        : i18next.t('commandes.14')
                                                }</span>
                                    }
                                </div>
                                {
                                    data && data.length ? data.map((item: any, key) => {
                                        return (
                                            <div className="mes-commandes-item" key={key}>
                                                <div className="mes-commandes-info">
                                                    <div>{
                                                        `${isCommand
                                                            ? i18next.t('commandes.5')
                                                            : i18next.t('commandes.11')
                                                        } : ${getDateTimestamp(item.orderDate)}`
                                                    }</div>
                                                    <span className="seperator">|</span>
                                                    <div>N° {
                                                        `${isCommand
                                                            ? i18next.t('commandes.6')
                                                            : i18next.t('commandes.12')
                                                        } : ${isCommand ? item.orderId : item.orderNumber}`
                                                    }</div>
                                                    <div className="mes-commandes-total-price">
                                                        {i18next.t('commandes.7')} : {replaceComma(isCommand ? item.totalSales : item.sales)} {getCurrency(item.currency)}
                                                    </div>
                                                </div>
                                                <div className="mes-commandes-item-content">
                                                    <div className="mes-commandes-item-image">
                                                        <h4 className="mes-commandes-item-title">
                                                            {
                                                                isCommand
                                                                    ? i18next.t('commandes.8')
                                                                    : i18next.t('commandes.13')
                                                            } : <span>{isCommand ? getDateTimestamp(item.shipmentDate) : item.storeName}</span>
                                                        </h4>
                                                        <div className="mes-commandes-item-image-wrapper">
                                                            {item.orderLines && item.orderLines.length && item.orderLines.filter(item => item.itemTypeCode !== "O").slice(0, 3).map((order, i) => {
                                                                return (
                                                                    <div className="mes-commandes-item-image-box"
                                                                        key={i}>
                                                                        <Photo src={`/${order.productPhotoUrl}`} discountDetail={""} />
                                                                    </div>
                                                                )
                                                            })}
                                                            {item.orderLines && item.orderLines.filter(item => item.itemTypeCode !== "O").length > 3 ?
                                                                <div className="mes-commandes-item-image-box">
                                                                    <div className="mes-commandes-item-image-count">
                                                                        +{item.orderLines.filter(item => item.itemTypeCode !== "O").length - 3}
                                                                    </div>
                                                                </div>
                                                                : ""
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="mes-commandes-item-buttons">
                                                        <div className="mes-commandes-item-buttons-box">
                                                            <button
                                                                className="button-style hoverButtonStyle"
                                                                onClick={() => this.toggleChild(isCommand ? "recap" : "ticket", item)}
                                                            >
                                                                {i18next.t('commandes.2')}
                                                            </button>
                                                            {
                                                                item.orderStatus &&
                                                                    item.orderStatus.code &&
                                                                    [
                                                                        ORDER_STATUS.SHI,
                                                                        ORDER_STATUS.PAI,
                                                                        ORDER_STATUS.DEP,
                                                                        ORDER_STATUS.WIT
                                                                    ].includes(item.orderStatus.code.toUpperCase()) ?
                                                                    <Link
                                                                        to={{
                                                                            pathname: this.state.retourRoute,
                                                                            state: { element: item }
                                                                        }}
                                                                        className="button-style hoverButtonStyle"
                                                                        onClick={() => this.toggleChild("return", item)}
                                                                    >
                                                                        {i18next.t('commandes.3')}
                                                                    </Link>
                                                                    :
                                                                    isCommand ?
                                                                        <button
                                                                            className="button-style disabled"
                                                                            disabled={true}
                                                                        >
                                                                            {i18next.t('commandes.3')}
                                                                        </button> : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : ""
                                }
                            </div>
                        </div>
                        :
                        showChild === "recap" ?
                            <RecapCommande showChild={this.state.showChild} order={selectedOrder} />
                            :
                            showChild === "ticket"
                                ? <TicketDetails order={selectedOrder} />
                                : <RetournerUnArticle handleUpdateChild={this.props.handleUpdateChild} />
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        orders: getMesCommandesOrders(state),
        tickets: getMesTicketsOrders(state),
        error: getError(state),
        status: getStatus(state),
        loading: getLoading(state),
        menuList: getMenu(state),
    }
};

const mapDispatchToProps = (dispatch): typeof MesCommandesActionCreators => {
    return bindActionCreators(MesCommandesActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(MesCommandes);
