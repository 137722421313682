import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReCaptcha } from "react-recaptcha-google";

import i18next from "i18next";
import { ContactUsActionCreators } from "../redux/actions";
import { getContactUsData, getSendResponse, getStatus } from "../redux/selectors";
import CurrentPage from "../../../../layouts/common/currentPage";

import { getMenu } from "../../../../layouts/main/header/redux/selectors";
import Input from "../../../common/input/Input";
import "./contactUs.scss";
import {BRAND_ID, RECAPTCHA_SITE_KEY, COUNTRY} from "../../../../utils/constants/variables";
import {emailIsValid, getApiPrefix, getLang} from "../../../../helpers/helpers"
import {Dropdown, Icon} from "semantic-ui-react";


interface Props {
    canonicalUrl?: string,
    location?: any,
    history?: any,
    menuList: any,
    menuItem?: any,
    contactUsData: any,
    contactUsSendResponse: any,
    status: any
}

interface State {
    form: any,
    validation: any,
    options: any,
    hasInvalidField: boolean
}

class ContactUs extends React.Component<Props & typeof ContactUsActionCreators> {

    state: State = {
        form: {
            name: "",
            email: "",
            phone: "",
            info: "",
            message: "",
            recaptcha: "",
            commande: ""
        },
        validation: {
            name: { required: true, invalid: false },
            email: { required: true, invalid: false },
            message: { required: true, invalid: false },
            info: { required: true, invalid: false },
            recaptcha: { required: true, invalid: false },
        },
        options: [],
        hasInvalidField: true
    };
    private recaptchaRef: any = false;

    componentDidMount() {
        this.props.canonicalUrl && this.props.location.pathname !== this.props.canonicalUrl ? this.props.history.push(this.props.canonicalUrl) : "";
        if (this.recaptchaRef) {
            this.recaptchaRef.reset();
        }
        this.props.contactUsStart();
    }

    componentWillReceiveProps(nextProps): void {
        if (nextProps.contactUsData && nextProps.contactUsData.ticketSubjectList) {
            const options: any = [];
            nextProps.contactUsData.ticketSubjectList && nextProps.contactUsData.ticketSubjectList.map(item => {
                options.push({ value: item.code, text: item.subject })
            });
            options.length && this.setState({ options })
        }
    }
    checkRequiredFields = (form, validation) => {
        let hasInvalidField = false
        for (let key in validation) {
            if (validation[key].required && (form[key].length <= 1)) {
                hasInvalidField = true
            }
        }

        return hasInvalidField
    }

    handleChange = (e: any) => {
        const { validation, form } = this.state;

        const { name, value } = e.target;

        if (name === "commande") {
            if (value.length > 9) {
                return;
            }
            let pattern = /\d+/;
            pattern.test(value);
        }

        this.setState((prevState: State) => ({
            form: {
                ...prevState.form,
                [name]: value
            },
            hasInvalidField: this.checkRequiredFields(form, validation)
        }))

    };

    handleChangeSelect = (name, value) => {
        this.setState((prevState: State) => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }))
    };

    handleSubmit = () => {
        let hasInvalidElement = false;
        const { validation, form } = this.state;
        const validEmail = emailIsValid(form.email);

        for (let key in validation) {
            if (validation[key].required && !form[key]) {
                hasInvalidElement = true;
                validation[key].invalid = true;
            } else {
                validation[key].invalid = false;
            }
        }

        if (!validEmail) {
            validation.email.invalid = true;
            hasInvalidElement = true
        }

        if (!hasInvalidElement) {
            const data = {
                brandId: BRAND_ID,
                contactName: form.name,
                contactPhone: form.phone,
                contactEmail: form.email,
                ticketSubjectCode: form.info,
                ticketMessage: form.message,
                orderId: form.commande,
                ticketMessageFrom: "C",
                language: getApiPrefix(),
                country: COUNTRY
            };

            this.props.contactUsSendStart(data)
        }
        this.setState({ validation })
    };

    handleLoadRecaptcha() {
        if (this.recaptchaRef) {
            this.recaptchaRef.reset();
        }
    }

    handleVerifyRecaptcha = (recaptchaToken) => {
        const { validation, form } = this.state;
        if (recaptchaToken) {
            this.setState((prevState: State) => ({
                form: {
                    ...prevState.form,
                    recaptcha: recaptchaToken
                },
                hasInvalidField: this.checkRequiredFields(form, validation)
            }))
            for (let key in validation) {

                if (validation[key].required && form[key]) {
                    this.setState((prevstate: State) => {
                        return {
                            ...prevstate,
                            hasInvalidField: false
                        }
                    })

                }

            }
        }
    };

    render() {
        const { form, validation, options } = this.state;
        const { menuItem, status } = this.props;

        return (
            <>
                {!status &&
                    <div className="contact-us">
                        {menuItem &&
                            <CurrentPage selectedItem={menuItem} />
                        }
                        <div className="container">
                            <div className="contact-us-block">
                                <div className="flexbox-wrapper">
                                    <div>
                                        <div className="input-box required">
                                            <Input invalid={validation.name.invalid} id="nom" name="name" value={form.name}
                                                text={i18next.t("contactUs.1")} handleChange={this.handleChange} />
                                        </div>
                                        <div className="input-box required">
                                            <Input invalid={validation.email.invalid} id="email" name="email"
                                                value={form.email} text={i18next.t("contactUs.2")}
                                                handleChange={this.handleChange} />
                                        </div>
                                        <div className="input-box">
                                            <Input invalid={false} id="tel" name="phone" value={form.phone}
                                                text={i18next.t("contactUs.3")} handleChange={this.handleChange} />
                                        </div>
                                        <div className="input-box">
                                            <Input type='number' invalid={false} name="commande" id="commande"
                                                value={form.commande} text={i18next.t("contactUs.7")}
                                                handleChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="input-box required">
                                            <span
                                                className={`label-text ${validation.info.invalid ? "invalid" : ""}`}>{i18next.t("contactUs.4")}</span>
                                            <Dropdown
                                                className="mainSelect "
                                                value={form.info}
                                                options={options}
                                                compact
                                                selection
                                                name={"info"}
                                                onChange={(e, { value, name }) => this.handleChangeSelect(name, value)}
                                                icon={<Icon name='angle down' />} />

                                        </div>
                                        <div className="input-box required default-input">
                                            <label htmlFor="infoText"
                                                className={validation.message.invalid ? "invalid" : ""}>
                                                {i18next.t("contactUs.5")}
                                            </label>
                                            <textarea id="infoText" name="message" value={form.message}
                                                onChange={this.handleChange}></textarea>
                                        </div>

                                    </div>
                                </div>
                                <div className="captcha-wrapper">
                                    <ReCaptcha
                                        ref={(el) => {
                                            this.recaptchaRef = el;
                                        }}
                                        render="explicit"
                                        hl={getLang()}
                                        sitekey={RECAPTCHA_SITE_KEY}
                                        onloadCallback={this.handleLoadRecaptcha}
                                        verifyCallback={this.handleVerifyRecaptcha}
                                    />
                                    <div
                                        className={validation.recaptcha.invalid ? "invalid" : "d-none"}>{i18next.t("validation.required", { field: i18next.t("fields.recaptcha") })}</div>
                                </div>

                                <button className={`main-btn ${this.state.hasInvalidField ? 'disabled' : 'hoverButtonStyle'}`} onClick={this.handleSubmit} disabled={this.state.hasInvalidField}>{i18next.t("contactUs.6")}</button>
                            </div>

                        </div>
                    </div>
                }
                {status === "success" &&
                    <div className="contact-us-return">
                        {menuItem &&
                            <CurrentPage selectedItem={menuItem} showDescription={false} />
                        }
                        <div className="container">
                            <div className="contact-us-return-wrapper">
                                <h3 className="title">{i18next.t("contactUs.return.1")}</h3>
                                <h5 className="sub-title">{i18next.t("contactUs.return.2")}</h5>
                                <p className="text">{i18next.t("contactUs.return.3")}</p>
                                <Link className="link" to={"/"}> {i18next.t("contactUs.return.4")} </Link>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}


const mapStateToProps = (state: any): Props => {
    return {
        menuList: getMenu(state),
        contactUsData: getContactUsData(state),
        contactUsSendResponse: getSendResponse(state),
        status: getStatus(state)
    }
};

const mapDispatchToProps = (dispatch): typeof ContactUsActionCreators => {
    return bindActionCreators(ContactUsActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
