import React from 'react';
import 'react-awesome-slider/dist/styles.css';
import {Link} from "react-router-dom";
import {getDataBySectionAndWidget, getLangPrefix} from "./../../../../helpers/helpers";
import i18next from "i18next";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
interface Props {
    data: any,getData: any,
}


class Couleurs extends React.Component<Props> {

    render() {
        const {data}= this.props
        return (
            <section className="couleurs">
                <div className="container">
                    <div className="dflex">
                        <div className="couleurs-content ">
                                <div className="couleurs-color">
                                    <div className="largBox"><img src={BASE_API_GATEWAY + getDataBySectionAndWidget(4,3,data,"PHOTO").value}/></div>
                                </div>
                            <div className="d-between info-box">
                                <h1 className="title"> <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(4,1,data).value}} /></h1>
                                <div className="description"> <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(4,2,data).value}} /></div>
                                <div className="jalla-link-btn"><Link to={`${getLangPrefix()}${getDataBySectionAndWidget(4,2,data).menuHref}`}>{i18next.t('home.collection.discover')}</Link></div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Couleurs;
