import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { IHomeState } from "../redux/reducers";
import { getError, getHome, getLoading, getRecommendedProductsData } from "../redux/selectors";
import { HomeActionCreators } from "../redux/actions";
import Spirit from "./components/spirit";
import Category from "./components/category";
import RecommendedProducts from "./components/recommendedProducts";
import Slider from "./components/slider";
import { getLanguage, getMenuLoading } from "../../../layouts/main/header/redux/selectors";
import PageMetaData from "../../common/pageMetaData/pageMetaData";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import { AWIN_JS, AWIN_JS_LIB, PERSONALIZATION, PRIVATE_SALE_POPUP, WAVEUM_URL } from "../../../utils/constants/variables";
import "./home.scss";
import { getApiPrefix, getDataBySectionAndWidget } from "../../../helpers/helpers";
import DiscountPopUp from "../../common/discountPopUp";
import i18next from "i18next";
import Couleurs from "./components/couleurs";
import LogoLoader from "../../../layouts/main/logoLoader";



interface State {
    width: any,
    showValidDiscount?: boolean,
    validDiscount?: any,
}

interface Props extends IHomeState {
    data: any,
    error: any,
    language: string,
    recommendedProducts: any,
    isLoading: boolean,
    menuIsLoading: boolean,
}

class Home extends React.Component<Props & typeof HomeActionCreators> {

    state: State = {
        width: window.innerWidth,
        validDiscount: false,
        showValidDiscount: false,
    };

    handleResize = () => { this.setState({ width: window.innerWidth }) };

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        const { menuItem, homeStart, location } = this.props;
        const params = new URLSearchParams(location.search);
        window.addEventListener('resize', this.handleResize);
        if (Object.keys(menuItem).length && menuItem.language === getApiPrefix()) {
            homeStart(menuItem.gridId)
        }
        if (location.state && Object.keys(location.state).length) {
            this.setState({ showValidDiscount: true, validDiscount: location.state.valid })
        }

        if (AWIN_JS === "1" && params.get("awc")) {
            this.setAffiliateData(params);
        }

        if (localStorage.getItem('awinAwc') && !document.getElementById('awinAwc')) {
            const script = document.createElement('script');
            script.defer = true;
            script.id = 'awinAwc';
            script.src = AWIN_JS_LIB
            document.body.append(script)
        }

        this.props.recommendedProductsStart();
    }

    componentDidUpdate(prevProps) {
        if (this.props.menuItem.language !== prevProps.menuItem.language) {
            this.props.homeStart(this.props.menuItem.gridId)
        }
    }

    getData = (key, type = "text") => {
        let result;
        const itemByKey = this.props.data.length && key ? this.props.data.find(item => item.name && item.name.toLowerCase() === key.toLowerCase()) : "";
        const regex = /(<([^>]+)>)/ig;
        switch (type) {
            case "text":
                result = itemByKey && itemByKey.value ? parse(itemByKey.value.replace(regex, '')) : "";
                break;
            case "image":
                result = itemByKey && itemByKey.value ? (BASE_API_GATEWAY + itemByKey.value) : "";
                break;
            case "menu-href":
                result = itemByKey ? itemByKey && itemByKey.menuHref : "/#";
                break;
            default:
                result = "";
        }
        return result;
    };

    setAffiliateData(params: URLSearchParams) {
        const { history } = this.props;
        const awinCampaign = params.get("sv_campaign_id");
        const awinAwc = params.get("awc");

        if (awinCampaign) localStorage.setItem("awinCampaign", awinCampaign);
        if (awinAwc) localStorage.setItem("awinAwc", awinAwc);

        history.push("/");
    }

    render() {
        const { width, showValidDiscount, validDiscount } = this.state;
        const { menuItem, data, recommendedProducts, isLoading, menuIsLoading } = this.props;
        if (menuIsLoading) {
            return null;
        }
        return (
            <main>
                <a href={WAVEUM_URL} style={{ display: 'none' }}>{WAVEUM_URL}</a>
                {isLoading ?
                    <LogoLoader />
                    :
                    <>
                        {
                            menuItem && Object.keys(menuItem).length && <PageMetaData pageKey={menuItem.key} data={data && data.filter(obj => obj.section === 1)} />
                        }

                        <Slider data={data && data.filter(obj => obj.section === 1)} menuItem={menuItem} />

                        <Category grid="3" keys={null} getData={this.getData}
                                  data={data && data.filter(obj => obj.section === 2)}/>

                        {
                            PERSONALIZATION === "1" && recommendedProducts ?
                                <RecommendedProducts recommendedProducts={recommendedProducts} sliderType="home"/>
                                : null
                        }

                        <Spirit data={data} />

                        {
                            data && data.filter(obj => obj.section === 4) && data.filter(obj => obj.section === 4).length ?
                                <Couleurs getData={this.getData} data={data && data.filter(obj => obj.section === 4)}/> :""
                        }

                        {showValidDiscount && PRIVATE_SALE_POPUP === "1" ?
                            <DiscountPopUp
                                title={<p>{`${i18next.t("product.basket.36")} `} </p>}
                                text={validDiscount.valid ? i18next.t("product.basket.35") :
                                    !validDiscount.valid && validDiscount.authenticated ? i18next.t("product.basket.20") :
                                        !validDiscount.valid && !validDiscount.authenticated ?
                                            i18next.t("product.basket.33") : i18next.t("product.basket.20")}
                                handleCancel={() => this.setState({ showValidDiscount: false, validDiscount: false })}
                                photo={getDataBySectionAndWidget(2, 1, data, "PHOTO")}
                            /> : ""
                        }

                    </>
                }

            </main>
        );
    }
}

const mapStateToProps = (state: any): Partial<Props> => {
    return {
        data: getHome(state),
        error: getError(state),
        language: getLanguage(state),
        recommendedProducts: getRecommendedProductsData(state),
        isLoading: getLoading(state),
        menuIsLoading: getMenuLoading(state),
    }
};

const mapDispatchToProps = (dispatch): typeof HomeActionCreators => {
    return bindActionCreators(HomeActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
