import React from "react";
import i18next from "i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLocationSearch } from "../../locationSearchInput/redux/selectors";
import { LocationSearchActionCreators } from "../../locationSearchInput/redux/actions";
import { getBasket, isAddedToCart, getError, getMaxLimit } from "../redux/selectors";
import { AddToCartActionCreators } from "../redux/actions";
import { IAddToCartState } from "../redux/reducers";
import { STORAGE_BASKET_INDEX, STORE_DELIVERY } from "../../../utils/constants/variables";
import { getbasketLineItems, getDataLineItems, getShippingCity, isExistProductInClickAndCollect } from "../../../helpers/helpers";
import { OrderActionCreators } from "../../basket/redux/actions";
import { MesAddressActionCreators } from "../../monEspace/container/components/MesAddress/redux/actions";
import { getMesAddress } from "../../monEspace/container/components/MesAddress/redux/selectors";
import ConfirmPopup from "../../common/confirmPopup/confirmPopup";
import SelectStore from "./selectStore";
import "./addToCart.scss";

interface Props extends IAddToCartState {
    locationSearchData: any;
    buttonText?: string,
    data?: any,
    address: any,
    handleAddedToCard?: any,
    toggleStoreLocator?: () => void,
    handleZindex?: () => void,
    multiple?: boolean,
    basket: any,
    handleAddToCartEvent?: any,
    menuItem?: any
}

interface State {
    visible: boolean,
    messages: string[],
    invalid: boolean,
    showInfoPopUp: boolean,
    enter: boolean,
    showSidePanel: boolean,
}

class AddToCart extends React.Component<typeof AddToCartActionCreators, & typeof LocationSearchActionCreators> {

    state: State = {
        visible: false,
        messages: [],
        invalid: false,
        showInfoPopUp: false,
        enter: false,
        showSidePanel: false,
    };

    componentDidMount() {
        const { multiple = true } = this.props;
        if (multiple) this.props.mesAddressStart();
    }

    componentDidUpdate(prevProps) {
        const { basket, data } = this.props;
        if ((this.props.locationSearchData && prevProps.locationSearchData) && Object.keys(this.props.locationSearchData).length !== Object.keys(prevProps.locationSearchData).length) {
            const storeCode = basket["store"] && basket["store"]["StoreInfo"] && `${basket["store"]["StoreInfo"].storeCode}`;
            const validStore = this.props.locationSearchData.filter(item => item.storeCode === storeCode);
            const product = getDataLineItems(data);
            if (Object.keys(this.props.locationSearchData).length && this.state.enter && validStore && validStore.length) {
                if (isExistProductInClickAndCollect(validStore[0].barcodeList, [...getbasketLineItems(), ...product])) {
                    this.addToCart();
                    this.props.locationSearchDestroyedStart();
                    this.setState({ enter: false });
                } else {
                    this.setState({ showInfoPopUp: true });
                    this.props.locationSearchDestroyedStart();
                    this.setState({ enter: false });
                    if (this.props.handleZindex) {
                        this.props.handleZindex();
                    }
                }
            } else if (this.state.enter) {
                this.setState({ showInfoPopUp: true });
                this.props.locationSearchDestroyedStart();
                this.setState({ enter: false });
                if (this.props.handleZindex) {
                    this.props.handleZindex();
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.isComplect && (nextProps.addedToCart || nextProps.maxLimit)) {
            let messages: string[] = [], invalid = false;
            if (nextProps.addedToCart) {
                messages.push(i18next.t("addToCart.1"));
                invalid = false;
            } else if (nextProps.maxLimit) {
                messages.push(i18next.t("addToCart.2"));
                invalid = true;
            }
            this.setState({ invalid, messages })
        }

        const nextData = Object.keys(nextProps.data);
        const data = Object.keys(this.props.data);
        if (nextData && data && nextData.length && data.length && nextData[0] !== data[0]) {
            this.setState({ visible: false })
        }
    }

    handleCloseInfoPopup = () => {
        this.setState({ showInfoPopUp: false });
        this.props.toggleStoreLocator();
        if (this.props.handleZindex) {
            this.props.handleZindex();
        }

    };

    addToCart = () => {
        let { data, isComplect = false, basket } = this.props;
        let messages: string[] = [], invalid = false;
        for (let productId of Object.keys(data)) {
            if (!isComplect) {
                if (data[productId].quantity === 0 || data[productId].quantity === "0") {
                    messages.push(i18next.t("addToCart.3"));
                    invalid = true;
                }
                if (!Object.entries(data[productId].size).length) {
                    messages.push(i18next.t("addToCart.4"));
                    invalid = true;
                }
            }
            if (!invalid) {
                data[productId].size.quantity = parseInt(data[productId].quantity);
                delete data[productId].quantity;
                if (data[productId].size.quantity > 0) {
                    this.props.addtocartStart(STORAGE_BASKET_INDEX, productId, { ...data[productId] })
                }
            }
        }

        this.setState({ invalid, messages, visible: true });
        if (this.props.handleAddedToCard) {
            this.props.handleAddedToCard();
        }
        this.props.handleAddToCartEvent ? this.props.handleAddToCartEvent(true, null, null, data) : null

    };

    handleAddToCart = () => {
        const { basket, data, menuItem } = this.props;
        if (data && Object.keys(data).length) {
            for (const key in data) {
                data[key]['menuId'] = menuItem.menuId
            }
        }
        const product = getDataLineItems(data);
        const storeDelivery = basket["store"]
            && basket["store"]["StoreInfo"]
            && `${basket["store"]["StoreInfo"].city}`.toLowerCase()
            || getShippingCity();
        const { size = {} } = data ? data[Object.keys(data)[0]] || {} : {};
        const storeDeliveryType = basket.store && basket.store.storeDelivery || '';

        if (size.webInventory === 0 || STORE_DELIVERY === '1') {
            this.setState({ showSidePanel: true })
            return
        }

        if (storeDelivery && STORE_DELIVERY === "1") {
            const collectStoreOptions: { location, body, queryParams } = {
                location: storeDelivery,
                body: { basketLineItems: [...getbasketLineItems(), ...product] },
                queryParams: STORE_DELIVERY === '1'
                    ? storeDeliveryType === 'DIS'
                        ? { shipment: true }
                        : { consignment: true }
                    : null
            }

            this.props.clickAndCollectStoresStart(collectStoreOptions);
            this.setState({ enter: true })
        } else {
            this.addToCart();
        }


    };

    render(): React.ReactNode {
        const { buttonText, data } = this.props;
        const { visible, invalid, messages, showInfoPopUp, showSidePanel } = this.state;
        return (
            <>
                <div className="addtocart">
                    <button className={`product-sell button-style ${this.props.isEnable ? "hoverButtonStyle" : "disable"}`}
                        disabled={!this.props.isEnable} onClick={this.handleAddToCart}><span>{buttonText}</span>
                    </button>
                    {
                        visible && messages.length ? messages.map((item, i) => <div
                            className={`message ${invalid ? "error" : "success"}`} key={i}>{item}</div>) : ""

                    }
                </div>
                {showInfoPopUp &&
                    <ConfirmPopup
                        handleCancel={this.handleCloseInfoPopup}
                        text={i18next.t('modal.2')} title={""}
                        footer={false}
                    />
                }

                {showSidePanel &&
                    <SelectStore
                        handleClose={() => this.setState({ showSidePanel: false })}
                        data={data}
                        addToCart={this.addToCart}
                        createOrder={this.props.createOrderStart}
                        handleError={(message) => {
                            this.setState({ visible: true, invalid: true, messages: [message] })
                        }}
                        address={this.props.address}
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    basket: getBasket(state),
    addedToCart: isAddedToCart(state),
    error: getError(state),
    maxLimit: getMaxLimit(state),
    locationSearchData: getLocationSearch(state),
    address: getMesAddress(state),
});

const mapDispatchToProps = (dispatch): typeof AddToCartActionCreators => {
    return bindActionCreators({ ...AddToCartActionCreators, ...OrderActionCreators, ...MesAddressActionCreators }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);

