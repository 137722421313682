import React from "react";
import i18next from "i18next";
import {Link} from "react-router-dom";
import {Accordion, Icon} from 'semantic-ui-react';
import {LazyImage} from "react-lazy-images";
import {getRouteByAltUrl1, getRouteByMenuKey} from "../../../../../helpers/helpers";
import background from "../../../../../assets/img/grey-background.webp";
import FooterItemMenu from "./footerItemMenu";
import Checkbox from "../../../../../modules/common/chckboxList/checkboxList";
import {BRAND_ID} from "../../../../../utils/constants/variables";
import ResponseMessage from "../../../../../modules/responseMessage/container";

interface Props {
    footerImg?: any,
    menuList: any,
    footerMenu: any,
    subscribeStart: any,
    status: any
}

class CAccordion extends React.Component<Props> {
    state = {
        activeIndex: -1,
        accept: false,
        email:'',
        error: false,
        subscribed: false,
        visibleMessage: false
    };

    componentDidUpdate(prevProps, prevState): void {
        if (this.props.status && !prevProps.status) {
            if(this.props.status.toUpperCase() === "SUCCESS"){
                this.setState({subscribed: true});
            }
            setTimeout(() => {
                this.setState({visibleMessage: false, email: "", accept: false})
            }, 5000)
        }
    };

    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({activeIndex: newIndex})
    };

    handleChange = (e) => {
        this.setState({accept: e.target.checked, error: false})
    };

    hendleChangeStateEmail = (e) => {
        this.setState({email: e.target.value})
    };

    handleSubscribe = (e) => {
        const {email, accept} = this.state;
        const body={
            email,
            "brandId": BRAND_ID
        }
        if (accept) {
            this.props.subscribeStart(body);
            this.setState({error: false})
        } else {
            this.setState({error: true})
        }
        this.setState({visibleMessage: true});
        e.preventDefault();
    };

    render() {
        const {
            activeIndex,
            email,
            error,
            accept,
            visibleMessage
        } = this.state;

        const {
            menuList,
            footerMenu,
            status
        } = this.props;


        return (
            <div className="footer-content-mobile">
                <div className="services">
                    <div className="services-img">
                        <LazyImage
                            src={this.props.footerImg && this.props.footerImg}
                            alt="footer"
                            placeholder={({ imageProps, ref }) => (
                                <img
                                    ref={ref}
                                    src={background}
                                    alt={imageProps.alt}

                                />
                            )}
                            actual={({ imageProps }) => (
                                <img {...imageProps}   alt={''}/>
                            )}
                        />

                        <div className="services-img-text">
                            <h4>{i18next.t('footer.contact.1')}</h4>
                            <p>{i18next.t('footer.contact.2')}</p>
                            <div className="jalla-link-btn">
                                <Link  to={getRouteByMenuKey(menuList, "contact").route}> {i18next.t('footer.contact.3')} </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <Accordion>
                    <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
                        {i18next.t('footer.news.1').toUpperCase()}
                        <Icon name={activeIndex === 0 ? 'minus':'plus'} className="pull-right"/>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <div className="contact">
                            {/*<input type="text" placeholder={i18next.t('footer.news.2')} className="email"/>*/}
                            {/*<div className="contact-check">*/}
                            {/*    <input type="checkbox" id="contact-check-input"/>*/}
                            {/*    <label htmlFor="contact-check-input"></label>*/}
                            {/*    <p className='label-mobile'>{i18next.t('footer.news.3')} <Link*/}
                            {/*        to={getRouteByAltUrl1(menuList, "personal_data")}*/}
                            {/*    >{i18next.t('footer.news.7')}</Link></p>*/}

                            {/*</div>*/}
                            {/*<button disabled={this.props.subscribed} className="subscribe-btn"*/}
                            {/*        type="submit">{i18next.t('footer.news.4')}</button>*/}
                            {/*/!*<a href="#" rel="noopener">{i18next.t('footer.news.4')}</a>*!/*/}
                            <form onSubmit={this.handleSubscribe}>
                                 <input
                                    id="email"
                                    type="email"
                                    placeholder={i18next.t('footer.news.2')}
                                    className="email"
                                    value={email}
                                    onChange={this.hendleChangeStateEmail}
                                />
                                <div className="contact-check">
                                    <Checkbox
                                        text={i18next.t('footer.news.3')}
                                        data={
                                            <Link
                                                to={getRouteByAltUrl1(menuList, "personal_data")}
                                            >{i18next.t('footer.news.7')}</Link>
                                        }
                                        name="connection_2"
                                        checked={accept}
                                        handleChange={this.handleChange}
                                        elemStyle={error ? "error" : ""}
                                    />
                                </div>
                                <button disabled={this.state.subscribed} className="subscribe-btn"
                                        type="submit">{i18next.t('footer.news.4')}</button>
                            </form>
                        </div>
                        {visibleMessage && status && accept ?
                            <ResponseMessage status={status} errorText={i18next.t('footer.news.6')}
                                             successText={`${this.state.email} ${i18next.t('footer.news.5')}`}/> : ""}


                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
                        {i18next.t('footer.social.1').toUpperCase()}
                        <Icon name={activeIndex === 1 ? 'minus':'plus'} className="pull-right"/>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <div className="social">
                            <div className="social-item">
                                <a href="https://www.facebook.com/BassettiDeutschland/?ref=page_internal"
                                   target="_blank" rel="noopener"><Icon name="facebook f"/></a>
                            </div>
                            <div className="social-item">
                                <a href="https://www.instagram.com/bassetti_deutschland/" target="_blank"rel="noopener"><Icon
                                    name="instagram"/></a>
                            </div>
                        </div>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
                        {i18next.t('footer.services.1').toUpperCase()}
                        <Icon name={activeIndex === 2 ? 'minus':'plus'} className="pull-right"/>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                        <ul className="services-links">
                            <FooterItemMenu
                                data={footerMenu}
                            />
                        </ul>
                    </Accordion.Content>
                </Accordion>
            </div>
        );
    }
}

export default CAccordion;
