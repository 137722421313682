import { language } from './../../../layouts/main/header/redux/reducers';
import { PaymentTypes, PaymentActionCreators } from "./actions";
import { takeLatest, put, call, all, select } from "redux-saga/effects";
import axios from 'axios';
import { GET_ORDER_AMOUNT, ORDER_MAC, ADYEN_SESSION_URL, ADYEN_AMOUNT_P, REDEEM_STORE_LOYALTY, WEB_CLIENT } from "../../../utils/constants/service";
import { getBasketData, getDateNow } from "../../../helpers/helpers"
import { BRAND_ID } from "../../../utils/constants/variables";
import { UserActionCreators } from "../../login/redux/actions";
import { OrderActionCreators } from "../../basket/redux/actions";
import { getBasket } from "../../addToCart/redux/selectors";
import { getBrandDetail } from "../../../layouts/main/header/redux/selectors";

export function* amount(params) {
    const { day, month, year, hours, minutes, seconds } = getDateNow();
    const tranDate = `${day}/${month}/${year}:${hours}:${minutes}:${seconds}`;

    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };
        const amount = yield call(() => {
            return axios.get(`${GET_ORDER_AMOUNT}${params.orderId}/amount`, headers)
                .then(res => res.data)
        });

        const body = {
            "tranDate": tranDate,
            "amount": amount,
            "currency": "EUR",
            "reference": Math.random().toString().slice(2, 10),
            "orderId": params.orderId,
            "email": params.email,
            "brandId": BRAND_ID
        }

        let data = yield call(() => {
            return axios.post(`${ORDER_MAC}`, body, headers)
                .then(res => res.data)
        });

        data = { ...data, encriptAmount: amount }
        yield put(PaymentActionCreators.amountSuccess(data));
    } catch (e) {
        console.log(e)
        yield put(PaymentActionCreators.amountFailure(e.message));
    }
}

export function* adyenSession(params) {
    const { body, callback } = params
    const { paymentGateway } = yield select(getBrandDetail);

    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };

        const amountP = yield call(() => {
            return axios.get(`${ADYEN_AMOUNT_P(body.orderId)}`, headers)
                .then(res => res.data)
        });

        body.amount = amountP * 100;
        body.gateway = paymentGateway;

        const session = yield call(() => {
            return axios.post(`${ADYEN_SESSION_URL}`, JSON.stringify(body), headers)
                .then(res => res)
        });

        yield put(PaymentActionCreators.adyenSessionSuccess(session.data));
        callback && callback(session.data)
    } catch (e) {
        yield put(PaymentActionCreators.adyenSessionFailure(e.message));
    }
}
export function* createClientLoyalty() {
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };

        const data = yield call(() => axios.put(`${REDEEM_STORE_LOYALTY}?brandId=${BRAND_ID}`, {}, headers).then(res => res.data));

        const userInfo = yield call(() => {
            return axios.get(WEB_CLIENT, headers)
                .then(res => res.data)
        });

        const discountCode = userInfo.discountCodes && userInfo.discountCodes[userInfo.discountCodes.length - 1]

        yield put(UserActionCreators.userInfoSuccess(userInfo));
        yield put(OrderActionCreators.validateDiscountStart(discountCode));
        yield put(PaymentActionCreators.createClientDiscountSuccess(data));
    } catch (error) {
        yield put(PaymentActionCreators.createClientDiscountFailure(error));
    }
}

export function* paymentSaga() {
    yield takeLatest(PaymentTypes.AMOUNT_START, amount);
    yield takeLatest(PaymentTypes.CREATE_CLIENT_DISCOUNT_START, createClientLoyalty);
    yield takeLatest(PaymentTypes.ADYEN_SESSION_START, adyenSession);
}

export default paymentSaga;

