import React from 'react';
import i18next from "i18next";
import { Link } from "react-router-dom";
import { BASE_API_GATEWAY } from "../../../../utils/constants/service";
import { getLangPrefix } from "../../../../helpers/helpers";
import ImgBlob from "./imgBlob";


const { detect } = require('detect-browser');

interface Props {
    getData: any,
    keys: any,
    data: any,
    grid?: string
}

class Category extends React.Component<Props> {

    render() {
        const browser = detect();
        const photo = this.props.data.filter(i => i.type === "PHOTO");
        const {grid="3"} = this.props
        const LANGUAGE_PREFIX = getLangPrefix();

        return (
            <section className="home-container">
                <div className="category  d-between">
                    {photo &&
                        photo.map((elem, i) => {
                            return elem.type && elem.type === "PHOTO" ? (<div className="category-item" key={i}>
                                {elem.menuHref ?
                                    <Link to={`${LANGUAGE_PREFIX}${elem && elem.menuHref}` || ""}>
                                        <div className="category-item-img">
                                            <ImgBlob src={`${BASE_API_GATEWAY}${elem && elem.value}`}
                                                alt={elem.altText || "category image"} />
                                            <div className="category-item-img-text">
                                                {
                                                    this.props.getData && this.props.getData(elem.name + "-description", "text")
                                                }
                                            </div>
                                        </div>
                                    </Link>
                                    :
                                    <div className="category-item-img">
                                        <ImgBlob src={`${BASE_API_GATEWAY}${elem && elem.value}`}
                                            alt={elem.altText || "category image"} />

                                        <div className="category-item-img-text">
                                            {
                                                this.props.getData && this.props.getData(elem.name + "-description", "text")
                                            }
                                        </div>
                                    </div>
                                }
                                <h2 className="category-item-name">
                                    {this.props.getData(elem.name + "-name", "text")}
                                </h2>
                                <div className="jalla-link-btn">
                                    <Link to={`${LANGUAGE_PREFIX}${this.props.getData(elem.name + "-name", "menu-href")}`}>
                                        {i18next.t('home.collection.discover')}
                                    </Link>
                                </div>
                            </div>
                            ) : (<div></div>)

                        })
                    }
                </div>
            </section>
        );
    }
}

export default Category;
