import React from "react";
import { Link } from "react-router-dom";
import {
  getDataBySectionAndWidget,
  getLangPrefix,
} from "../../../../helpers/helpers";
import { BASE_API_GATEWAY } from "../../../../utils/constants/service";
import { BASSETTI_CASA } from "../../../../utils/constants/variables";

interface Props {
  data: any;
}

class Spirit extends React.Component<Props> {
  render() {
    const data = (this.props.data || []).filter((item) => item.section === 3);
    const {
      value: photoUrl = "",
      menuHref = "",
      name,
    } = getDataBySectionAndWidget(3, 1, data);

    if (!photoUrl) {
      return "";
    }

    const { value: title = "" } = getDataBySectionAndWidget(3, 2, data);
    const { value: description = "" } = getDataBySectionAndWidget(3, 3, data);
    const isTarget = name && name.toUpperCase() === "BASSETTI CASA";

    const content = title || description ? (
        <div className="spirit-content-links">
          <div className="title">
            {" "}
            <p dangerouslySetInnerHTML={{ __html: title }} />{" "}
          </div>
          <div className="description">
            {" "}
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      ) : (
        ""
      )

    return (
      <section className="spirit">
        <div className="container">
          {isTarget ? (
            <a
              href={BASSETTI_CASA}
              target="_blanck"
              className="spirit-content "
              style={{ backgroundImage: `url(${BASE_API_GATEWAY}${photoUrl})` }}
            >
              {content}
            </a>
          ) : (
            <Link
              to={`${getLangPrefix()}${menuHref}`}
              className="spirit-content "
              style={{ backgroundImage: `url(${BASE_API_GATEWAY}${photoUrl})` }}
            >
              {content}
            </Link>
          )}
        </div>
      </section>
    );
  }
}

export default Spirit;
