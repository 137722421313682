import React from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import i18next from "i18next";
import Payment from "../../../payment/container"
import ConfirmPopup from "../../../common/confirmPopup/confirmPopup";
import { getMesAddress, getMesAddressSend } from "../../../monEspace/container/components/MesAddress/redux/selectors";
import { MesAddressActionCreators } from "../../../monEspace/container/components/MesAddress/redux/actions";
import {
	DELIVERY_PRICE,
	DEFAULT_CURRENCY,
	STORAGE_BASKET_INDEX,
	BRAND_ID,
	STORE_DELIVERY, FOREIGN_DELIVERY, COUNTRY,
	VALIDATE_DISCCOUNT, STORE_DEPOT, ORDER_STATUS, LANGUAGE,
	DISCOUNT_CODE
} from "../../../../utils/constants/variables";
import { OrderActionCreators } from "../../redux/actions";
import { getStatus, getError, getCreateOrder, getUser, getDeliveryMethod, getDiscount, getorderStatus } from "../../redux/selectors";
import {
	getLocalStorageObject,
	getOrderByStatus,
	getBasketData,
	afterOrderUpdateLocalStorage,
	decryptCryptr,
	setStoreToBasket,
	getBasketProductsBySizes,
	createWebOrderLineItems,
	getbasketLineItems,
	getShippingCity,
	getBasketStore,
	getLangPrefix,
	getLivrasionEstimate,
	getConvertedItemDataForGA4,
	tooFixed,
	isLoggedin,
	getMenuItemByMenuId,
	getMenuItemByMenuIdElem,
	isProductsInBarcode
} from "../../../../helpers/helpers";
import ChooseNearestStore from "../../../location/container/chooseNearestStore";
import { getLocationSearch } from "../../../locationSearchInput/redux/selectors";
import { StoreLocationActionCreators } from "../../../location/redux/actions";
import { getUserStore } from "../../../location/redux/selectors";
import { getBasket } from "../../../addToCart/redux/selectors";
import { LocationSearchActionCreators } from "../../../locationSearchInput/redux/actions";
import Summary from "./summary";
import DiscountPayment from "./discountPayment";
import { withRouter } from "react-router-dom";
import { getBrandDetail, getMenu } from "../../../../layouts/main/header/redux/selectors";
import { getUserInfo } from "../../../login/redux/selectors";
import { UserActionCreators } from "../../../login/redux/actions";
import { TrackBeginCheckout } from "../../../../helpers/ga4Tracker";
import { AddressPopup } from "../../../basketRefactor/container/components/addressPopup";

interface Props {
	address: any,
	items?: any,
	status: string,
	orderStatus: string,
	error: string,
	orders?: any,
	newOrder: any,
	user: any,
	mesAddressSend: any,
	locationSearchData?: any,
	userStore?: any,
	deliveryMethod?: string,
	scrollToRef?: () => void,
	payRef?: React.RefObject<any>,
	basket?: any,
	discount?: any,
	menuList?: any,
	brandDetail?: any,
	webClient: any,
	userInfoStart?: any;
}

interface State {
	showAddressPopup: boolean,
	maxAddressCount: number,
	typeBilling: string,
	typeShipping: string,
	addressItem: any,
	shownBillingAddress: boolean,
	showConfirmPopup: boolean,
	addressId?: any,
	addressType?: any,
	billingAddress: any,
	shippingAddress: any,
	selectedBillingAddress: number,
	selectedShippingAddress: number,
	selectedPrice: string,
	showMessage: boolean,
	errorMessage: string,
	orderMessage: string,
	isEnable: boolean,
	isCheOrder: boolean,
	newAddress: any,
	isSelectedStore: boolean,
	showInfoPopup: boolean,
	storeCode: string | null | number,
	showProductsInfo: string,
	isAddBasketAllow: boolean
}

class DeliveryPayment extends React.Component<Props & typeof MesAddressActionCreators & typeof OrderActionCreators> {
	state: State = {
		showAddressPopup: false,
		maxAddressCount: 3,
		typeBilling: "BILL",
		typeShipping: "SHIP",
		addressItem: {},
		shownBillingAddress: false,
		showConfirmPopup: false,
		billingAddress: [],
		shippingAddress: [],
		selectedBillingAddress: 0,
		selectedShippingAddress: 0,
		selectedPrice: "1",
		showMessage: false,
		errorMessage: i18next.t('payment.12'),
		orderMessage: "",
		isEnable: true,
		isCheOrder: false,
		newAddress: {},
		isSelectedStore: false,
		showInfoPopup: false,
		storeCode: "",
		showProductsInfo: '',
		isAddBasketAllow: false,
	};
	componentDidMount = () => {
		const isProductsInBarcodeStatus = isProductsInBarcode();
		this.props.mesAddressStart();
		this.props.storeLocationStart({});
		const { webClient, basket } = this.props;
		if (this.props.basket && this.props.basket["store"] && ["STO", "DEP"].includes(this.props.basket["store"]["storeDelivery"])) {
			const { selectedPrice } = this.state
			const { basket } = this.props;
			const storeDelivery = basket && basket["store"] && basket["store"]["storeDelivery"];
			this.props.selectedDeliveryMethodStart("storeDelivery")

			this.setState({
				selectedPrice: "0",
				shownBillingAddress: selectedPrice === "0" && ["STO"].includes(storeDelivery)
					&& this.props.basket["store"]["storeDelivery"] !== "DIS"
			})
		} else {
			const { totalPrice } = getBasketData(this.props.basket)
			this.props.selectedDeliveryMethodStart("onlineDelivery")
			if (totalPrice < DELIVERY_PRICE) {
				this.setState({ selectedPrice: "1" })
			}
		}
		if (this.props.address && this.props.address.length) {
			const shippingAddress = this.props.address.filter(item => item.addressType === this.state.typeShipping);
			const billingAddress = this.props.address.filter(item => item.addressType === this.state.typeBilling);
			this.setState({ billingAddress, shippingAddress }, () => {

				const selectedShippingAddress = shippingAddress.find(item => item.isDefault === true)
				const selectedBillingAddress = billingAddress.find(item => item.isDefault === true)

				if (selectedShippingAddress) {
					this.setState({ selectedShippingAddress: selectedShippingAddress.clientAddressId })
				}

				if (selectedBillingAddress) {
					this.setState({ selectedBillingAddress: selectedBillingAddress.clientAddressId })
				}

				if (selectedShippingAddress && selectedBillingAddress && selectedShippingAddress.clientAddressId !== selectedBillingAddress.clientAddressId) {
					this.setState({ shownBillingAddress: true })
				}
			})
		}

		if (webClient && webClient.discountCodes && webClient.discountCodes[0] && !isProductsInBarcodeStatus) {
			const { price } = getBasketData(basket);
			this.props.validateDiscountStart(
				webClient.discountCodes[0] || "",
				price,
				"submitBtn"
			);
		}
		if (basket && Object.keys(basket).length && isLoggedin()) {
			const { price } = getBasketData(basket);
			const items = [] as any
			const vDiscount = getLocalStorageObject('validateDiscount')
			const { value = 0 } = vDiscount
			const dValue = tooFixed(value > 0 ? price - (price / value) : price, 2)
			Object.keys(basket).map((item: any) => {
				if (item !== 'store') {
					const basketMenuItem = getMenuItemByMenuIdElem(this.props.menuList, basket[item].menuId)
					basket[item].sizes && basket[item].sizes.length && basket[item].sizes.map((sizeElement, i) => {
						const dataT = getConvertedItemDataForGA4(this.props.menuItem || basketMenuItem, basket[item], sizeElement.quantity && sizeElement.quantity > 0 ? sizeElement.quantity : 1, sizeElement);
						items.push(dataT)
					})
				}
			})
			TrackBeginCheckout(items, dValue)
		}

		this.props.userInfoStart();
	};

	componentWillReceiveProps = (nextProps) => {
		if (this.props.address && nextProps.address && nextProps.address.length !== this.props.address.length) {
			const shippingAddress = nextProps.address.filter(item => item.addressType === this.state.typeShipping);
			const billingAddress = nextProps.address.filter(item => item.addressType === this.state.typeBilling);
			this.setState({ billingAddress, shippingAddress });
			if ((billingAddress.length || shippingAddress.length > 1) && Object.keys(this.state.newAddress).length) {
				this.setState({ newAddress: {}, orderMessage: "" })
			} else if (billingAddress && billingAddress.length === 0 && shippingAddress.length === 1 && this.props.mesAddressSend && Object.keys(this.props.mesAddressSend).length > 0) {
				this.setState({
					newAddress: { ...this.props.mesAddressSend, addressType: this.state.typeBilling, clientAddressId: 0 },
					shownBillingAddress: true,
					orderMessage: i18next.t('addToCart.7')
				})
			} else if (billingAddress && billingAddress.length === 0) {
				this.setState({ isEnable: true, shownBillingAddress: false })
			}

			const selectedShippingAddress = shippingAddress.find(item => item.isDefault === true)
			const selectedBillingAddress = billingAddress.find(item => item.isDefault === true)

			if ((selectedBillingAddress && Object.keys(selectedBillingAddress).length) && (selectedShippingAddress && Object.keys(selectedShippingAddress).length)) {
				this.setState({ orderMessage: "" })
			}

			if (selectedShippingAddress) {
				this.setState({ selectedShippingAddress: selectedShippingAddress.clientAddressId })
			}

			if (selectedBillingAddress) {
				this.setState({ selectedBillingAddress: selectedBillingAddress.clientAddressId })
			}

			if (selectedShippingAddress && selectedBillingAddress && selectedShippingAddress.clientAddressId !== selectedBillingAddress.clientAddressId) {
				this.setState({ shownBillingAddress: true })
			}

		}

		if (nextProps.newOrder && nextProps.newOrder.length && ["AWA", "CHE"].includes(nextProps.newOrder[0].orderStatus) && (!this.props.newOrder[0] || nextProps.newOrder[0].webOrderId !== this.props.newOrder[0].webOrderId)) {
			this.setState({ isCheOrder: true })
			afterOrderUpdateLocalStorage(STORAGE_BASKET_INDEX, nextProps.newOrder);
			this.props.getBasketStart();
		}
		if (this.state.isCheOrder) {
			this.props.scrollToRef(this.props.payRef)
		}
	};

	componentDidUpdate(prevProps, prevState) {
		const { locationSearchData, basket } = this.props;
		const { isAddBasketAllow } = this.state;

		if (isAddBasketAllow) {
			if (prevProps.locationSearchData !== locationSearchData && locationSearchData && locationSearchData.length) {

				const isAllStoreHasBasket = locationSearchData.reduce((acc, item) => {
					return item.hasBasket ? acc = true : acc
				}, false)

				if (isAllStoreHasBasket) {
					setStoreToBasket({ StoreInfo: locationSearchData[0], type: "DIS" });
					this.props.getBasketStart();
					this.setState({ isAddBasketAllow: false })
				} else {
					this.setState({ showInfoPopup: true });
				}
			}
		}

		if (STORE_DELIVERY === "1" && this.props.userStore.storeId && this.props.userStore.storeId !== prevProps.userStore.storeId) {
			const selectedShippingAddress = this.state.shippingAddress.find(item => item.isDefault === true)
			const selectedShippingAddressId = selectedShippingAddress && selectedShippingAddress.clientAddressId ? selectedShippingAddress.clientAddressId : 0
			this.setState({
				shownBillingAddress: true,
				isSelectedStore: true,
				selectedShippingAddress: selectedShippingAddressId,
			})
			if (!prevState.shownBillingAddress) {
				const selectedBillingAddress = this.state.billingAddress.find(item => item.isDefault === true);
				const selectedBillingAddressId = selectedBillingAddress && selectedBillingAddress.clientAddressId ? selectedBillingAddress.clientAddressId : 0;
				const selectedShippingAddress = this.state.shippingAddress.find(item => item.isDefault === true);
				const selectedShippingAddressId = this.state.selectedShippingAddress ? this.state.selectedShippingAddress : selectedShippingAddress && selectedShippingAddress.clientAddressId ? selectedShippingAddress.clientAddressId : 0;
				this.setState({ selectedBillingAddress: selectedBillingAddressId, selectedShippingAddress: selectedShippingAddressId });
			}

		}
		if (prevState.selectedPrice === "2" && this.state.selectedPrice !== prevState.selectedPrice) {
			this.setState({ isSelectedStore: false })
		}
		if (prevState.selectedPrice && prevState.selectedPrice !== this.state.selectedPrice) {
			this.props.createOrderSuccess([])
		}
		if (prevProps.newOrder && this.props.newOrder && prevProps.newOrder.length && !this.props.newOrder.length) {
			this.setState({ isCheOrder: false })
		}

		if (
			(this.props.basket && this.props.basket["store"])
			&& (prevProps.basket && prevProps.basket["store"])
			&& this.props.basket["store"]["storeDelivery"] !== prevProps.basket["store"]["storeDelivery"]
			&& this.props.basket["store"]["storeDelivery"] === "DIS"
		) {
			this.props.selectedDeliveryMethodStart("onlineDelivery")
		} else if (
			(this.props.basket && this.props.basket["store"])
			&& (prevProps.basket && prevProps.basket["store"])
			&& this.props.basket["store"]["storeDelivery"] !== prevProps.basket["store"]["storeDelivery"]
			&& this.props.basket["store"]["storeDelivery"] === "STO"
		) {
			this.props.selectedDeliveryMethodStart("storeDelivery")
			this.setState({ shownBillingAddress: true })
		}

		if (!prevProps.orderStatus && this.props.orderStatus && this.props.orderStatus === "success" && Array.isArray(this.props.newOrder) && this.props.newOrder.length) {
			const order = this.state.storeCode ?
				this.props.newOrder.filter(item => +item.storeStock === 0)
				:
				this.props.newOrder.filter(item => +item.webStock === 0)

			if (!order.length) return;

			this.props.cleareCreatedOrderStart()
			let showProductsInfo = ''

			if (order.length === 1) {
				showProductsInfo = i18next.t('product.basket.delivery.15', { productName: order[0].productName })
			} else {
				let names = ''
				order.forEach((item) => {
					names += `${item.productName}, `
				});
				showProductsInfo = i18next.t('product.basket.delivery.16', { productName: names })
			}
			this.setState({ showProductsInfo })
			this.props.scrollToRef(this.props.payRef)
		}

		if (this.props.basket !== prevProps.basket) {
			const { price } = getBasketData(basket);
			const { storeDelivery } = getBasketStore();
			const validateDiscount = getLocalStorageObject(VALIDATE_DISCCOUNT);
			const discountCode = localStorage.getItem(DISCOUNT_CODE);
			const number = ["STO", "DEP"].includes(storeDelivery) ? "0" : "1";

			if (discountCode) {
				this.props.validateDiscountStart(
					validateDiscount.discountCode || discountCode,
					price,
					"submitBtn"
				);
			}

			this.setState({ selectedPrice: number });
		}

		// if client has discountCode
		const { webClient } = this.props;
		if (prevProps.webClient !== webClient && webClient && webClient.discountCodes && webClient.discountCodes[0]) {
			const { price } = getBasketData(basket);
			this.props.validateDiscountStart(
				webClient.discountCodes.at(-1) || "",
				price,
				"submitBtn"
			);
		}

	}

	changeSelectedPrice = (num: string, method = "onlineDelivery") => {
		const { basket, locationSearchData } = this.props;
		this.setState({ selectedPrice: num, shownBillingAddress: false });
		const selectedStore = basket && basket["store"] && basket["store"]["StoreInfo"];
		const products = getBasketProductsBySizes(basket).filter(product => product.webInventory === 0);
		if (selectedStore && !selectedStore.hasBasket && (products && products.length)) {
			this.setState({ showInfoPopup: true });
			return;
		}
		if (num === "1") {
			this.setState({ isAddBasketAllow: true })

			setStoreToBasket({});
			this.props.getBasketStart();
			this.props.resetStoreLocationStart();
			const shippingCity = getShippingCity();
			this.props.clickAndCollectStoresStart({
				location: shippingCity,
				body: { basketLineItems: getbasketLineItems() },
				queryParams: { consignment: false, shipment: true }
			})
			this.props.selectedDeliveryMethodStart(method);
		}
		if (STORE_DELIVERY === "1" && this.props.deliveryMethod !== method) {
			this.props.selectedDeliveryMethodStart(method)
		}
	};

	handleCloseInfoPopup = () => this.setState({ showInfoPopup: false });

	toggleAddressPopup = (id: any = "", type: any = "", e) => {
		e.stopPropagation();
		let item: any = {};
		if (type === this.state.typeBilling) {
			item = this.state.billingAddress.find(obj => obj.clientAddressId === id);
		} else {
			item = this.state.shippingAddress.find(obj => obj.clientAddressId === id);
		}
		if (!item) {
			item = { addressType: type };
		}
		this.setState({ showAddressPopup: !this.state.showAddressPopup, addressItem: item, addressType: type })
	};

	handleUpsertAddressItem = (data: any = {}) => {
		let addresses = data.addressType === this.state.typeBilling ? this.state.billingAddress : this.state.shippingAddress;
		if (!data.clientAddressId && !data.webClientId) {
			const name = data.name ? data.name.split(" ") : "";
			const firstName = name[0];
			name.shift();
			const lastName = name.join(" ");
			const body = {
				"address1": data.address1 || "",
				"address2": data.address2 || "",
				"addressType": data.addressType || "",
				"city": data.city || "",
				"clientAddressId": 0,
				"firstName": firstName,
				"lastName": lastName,
				"country": data.country || "",
				"email": this.props.user.email || "",
				"isDefault": true,
				"name": data.name || "",
				"phone": data.phone || "",
				"zip": data.zip || ""
			};
			this.props.mesAddressPostStart(body);
		} else {
			const objIndex = addresses.findIndex(obj => obj.clientAddressId == data.clientAddressId);
			//REMOVE AFTER LASTNAME AND FIRSTNME VALUE
			const seperated = data.name.split(" ");
			data.firstName = seperated[0];
			seperated.shift();
			data.lastName = seperated.join(" ");
			//end remove
			this.props.mesAddressPostStart(data);
			addresses[objIndex] = data;
		}
		this.setState({ showAddressPopup: false })
	};

	handleChangeShownBilling = () => {
		const { shownBillingAddress, selectedShippingAddress, billingAddress, shippingAddress, typeBilling, newAddress, isSelectedStore } = this.state;
		if (isSelectedStore) {
			return false
		}

		if (billingAddress.length === 0 && shippingAddress.length === 1 && newAddress.addressType === typeBilling) {
			this.props.mesAddressPostStart(newAddress);
			this.setState({ newAddress: {}, orderMessage: "" })
		}
		let billingAddressId = 0;
		if (billingAddress && billingAddress.length && billingAddress[0].clientAddressId) {
			billingAddressId = billingAddress[0].clientAddressId
		}
		shownBillingAddress ? this.setState({ selectedBillingAddress: selectedShippingAddress }) : this.setState({ selectedBillingAddress: billingAddressId });
		this.setState({ shownBillingAddress: !this.state.shownBillingAddress })

	};

	toggleRemovePopup = (id: any = "", type: string = "", e) => {
		if (e) {
			e.stopPropagation();
		}
		this.setState({ showConfirmPopup: !this.state.showConfirmPopup, addressId: id, addressType: type })
	};

	handleRemoveAddress = () => {
		const { addressId, addressType } = this.state;
		this.props.mesAddressRemoveStart({ addressType, clientAddressId: addressId });
		this.upsertSelectedAddressList(addressId, addressType);
		this.setState({ showConfirmPopup: !this.state.showConfirmPopup, addressId: "", addressType: "" })
	};

	upsertSelectedAddressList = (id: any = 0, type: string = "") => {
		const { shownBillingAddress, typeBilling, selectedBillingAddress, selectedShippingAddress, typeShipping, showMessage } = this.state;
		this.setState({ orderMessage: "", isCheOrder: false });
		let selectedAddress = type === typeBilling ? selectedBillingAddress : selectedShippingAddress;
		selectedAddress = selectedAddress === id ? 0 : id;
		(type === typeBilling) ? this.setState({ selectedBillingAddress: selectedAddress }) : this.setState({ selectedShippingAddress: selectedAddress });
		(type !== typeBilling && !shownBillingAddress) && this.setState({ selectedBillingAddress: selectedAddress });
		if (type === typeShipping && showMessage) {
			this.setState({ showMessage: false })
		}
	};

	createOrder = (data, discountCode = "") => {
		const { items, userStore, basket, deliveryMethod, brandDetail, getBasketStart } = this.props;
		const validStore = (data || []).find((location) => location.hasBasket);

		if (validStore) {
			basket["store"] = {};
			basket["store"]["StoreInfo"] = validStore;
			localStorage.setItem(STORAGE_BASKET_INDEX, JSON.stringify(basket));
			getBasketStart(basket);
		}

		const selectedStore = basket["store"] && basket["store"]["StoreInfo"];
		const { selectedBillingAddress, selectedShippingAddress, isSelectedStore, shippingAddress, billingAddress, selectedPrice } = this.state;
		const { price, isPromoDiscountApplyed } = getBasketData(items);
		const validateDiscount = getLocalStorageObject(VALIDATE_DISCCOUNT);
		const { webOrderLineItems, key } = createWebOrderLineItems(items);
		const findAddress = shippingAddress.find(item => item.clientAddressId === selectedShippingAddress) || {};
		const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
		const isTypeExist = (brandDetail && brandDetail.promotion || []).find((item) => ["AMOUNT", "VALUE", "QUANTITY", "PERCENTAGE", "PACK"].includes(item.ruleType));

		if (storeDelivery !== "STO" && !shippingAddress.length) {
			this.setState({ showMessage: true, errorMessage: i18next.t('product.basket.delivery.4') })
			return;
		}

		if (FOREIGN_DELIVERY === "0" && !isSelectedStore) {
			if (storeDelivery !== "STO") {
				if (findAddress.countryCode && findAddress.countryCode.toUpperCase() !== LANGUAGE.toUpperCase()) {
					this.setState({ showMessage: true, errorMessage: i18next.t('payment.12') })
					return;
				}
			}
		}

		if (price < DELIVERY_PRICE && webOrderLineItems.length) {
			if (storeDelivery !== 'DEP' && storeDelivery !== 'STO') {
				const itemType = selectedPrice === "3" ? "FREIGHT_EXP" : "FREIGHT_COST";
				webOrderLineItems.push({
					orderLineId: key,
					currency: DEFAULT_CURRENCY,
					itemType
				})
			}
		}

		const shippingAddressId = deliveryMethod === "storeDelivery" && storeDelivery === "STO"
			? (shippingAddress.find(item => item.isDefault === true) || {}).clientAddressId
			: selectedShippingAddress
				? selectedShippingAddress
				: (shippingAddress.find(item => item.isDefault === true) || {}).clientAddressId

		const billingAddressId = selectedBillingAddress
			? selectedBillingAddress
			: (billingAddress.find(item => item.isDefault === true) || {}).clientAddressId

		this.setState({ showMessage: false });

		if (webOrderLineItems.length && billingAddressId) {
			const storeCode = isSelectedStore && userStore.storeCode
				? userStore.storeCode
				: selectedStore && Object.keys(selectedStore).length
					? selectedStore.storeCode : ""

			const body = {
				billingAddressId,
				brandId: BRAND_ID,
				discountCode: validateDiscount.discountCode || "",
				orderId: null,
				country: COUNTRY,
				orderStatus: ORDER_STATUS.CHE,
				shippingAddressId,
				storeCode,
				webOrderLineItems,
				browserId: decryptCryptr("browserId"),
				...(isTypeExist && isTypeExist.promotionRuleId !== undefined && isPromoDiscountApplyed && { promotionRuleId: isTypeExist.promotionRuleId })
			};
			if (discountCode) { body["discountCode "] = discountCode }

			this.props.createOrderStart(body);
			this.setState({ storeCode })
		} else if (!billingAddressId) {
			this.setState({ orderMessage: i18next.t("addToCart.6") })
		} else {
			this.setState({ orderMessage: i18next.t("addToCart.5") })
		}
	}

	handleCreateOrder = (discountCode = "") => {
		if (STORE_DELIVERY === "0") {
			return this.createOrder([], discountCode);
		}
		const { basket } = this.props;
		const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
		const basketLineItems = getbasketLineItems();

		basketLineItems.length && this.props.clickAndCollectStoresStart({
			location: getShippingCity(),
			body: { basketLineItems },
			queryParams: storeDelivery === 'DIS'
				? { shipment: true }
				: { consignment: true }
		}, (data) => {
			this.createOrder(data, discountCode);
		})
	};

	modifier() {
		const mesAddressMenu = this.props.menuList.find((menu) => menu.key === "mes_adresses");
		const LANGUAGE_PREFIX = getLangPrefix();
		const url = `${LANGUAGE_PREFIX}${mesAddressMenu.canonicalUrl}`
		this.props.history.push(url)
	}

	render() {
		const { basket, changeQuantity, removeItem, payRef, discount, totalPrice } = this.props;
		const { orderMessage, isCheOrder, selectedPrice, selectedBillingAddress, selectedShippingAddress, showAddressPopup, maxAddressCount,
			typeBilling, typeShipping, billingAddress, shippingAddress, addressItem, shownBillingAddress, showConfirmPopup, newAddress,
			isSelectedStore, showMessage, showInfoPopup, showProductsInfo, errorMessage } = this.state;
		const selectedStore = basket && basket["store"] && basket["store"]["StoreInfo"];
		const storeDelivery = basket && basket["store"] && basket["store"]["storeDelivery"];
		const isDisabled = storeDelivery === "STO" ? isCheOrder
			:
			isCheOrder || !!Object.keys(newAddress).length || (shownBillingAddress && !selectedBillingAddress)
			|| !selectedShippingAddress || (isSelectedStore && !selectedBillingAddress);

		const title = selectedPrice === "1" ? i18next.t('space.address.1')
			:
			storeDelivery === "DIS" ? i18next.t('product.basket.delivery.4') : i18next.t('product.basket.delivery.14');
		const label = storeDelivery === "STO" ? i18next.t('payment.17') : i18next.t('payment.16')
		const defaultShippingAddress = shippingAddress.find(item => item.isDefault === true)
		const defaultBillingAddress = billingAddress.find(item => item.isDefault === true)

		return (
			<div className="delivery-payment">
				<div className="delivery-payment-content">
					<div className="delivery-payment-wrapper">
						<div className="card-container-wrapper">
							<div className="card-container">
								<div className="card-left-container direction-between">
									<h4 className="delivery-payment-title">{title}</h4>
									<div className={`delivery-payment-adress ${["STO", "DEP"].includes(storeDelivery) ? "store" : ""}`}>
										<div className="delivery-payment-adress-item-content dflex flex-wrap">
											{shippingAddress.map(item => (
												item.isDefault && <div
													className={`item ${selectedShippingAddress === item.clientAddressId ? "item-default" : "item-choose-address"} ${storeDelivery === "STO" ? 'grayed' : ""}`}
													key={`shipping-${item.clientAddressId}`}
													onClick={() => this.upsertSelectedAddressList(item.clientAddressId, typeShipping)}
												>
													<div className='item-content'>
														<div className="item-content-flex-box">
															<div className='info-box'>
																<p className="user-name">{item.name}</p>
																<p className="address">{`${item.address1} ${item.address2 || ''}`}</p>
																<p className="zip">{`${item.zip} ${item.city}`}</p>
																<p className="country">{item.country}</p>
																<p className="phone">{item.phone}</p>
															</div>
															<div
																className='del-btn c-pointer'
																onClick={(e) => this.toggleRemovePopup(item.clientAddressId, typeShipping, e)}
															>
															</div>
														</div>
														<span
															className="main-link"
															onClick={this.modifier.bind(this)}
														>{i18next.t("space.address.3")}
														</span>
													</div>
													{selectedShippingAddress === item.clientAddressId ?
														<button className="main-btn">{i18next.t("space.address.7")}</button>
														:
														<span
															className="main-link"
															onClick={() => { }}
														>{i18next.t("space.address.7")}</span>
													}
												</div>
											))}
											{!defaultShippingAddress &&
												<div className="delivery-payment-adress-item dflex flex-column justify-content-center">
													<div className={"addAddress"}>
														<h4 className="item-title">{i18next.t('product.basket.delivery.6')}</h4>
														<div
															className="delivery-payment-adress-item-plus dflex justify-content-center align-items-center"
															onClick={(e) => this.toggleAddressPopup("", typeShipping, e)}>
														</div>
													</div>

												</div>
											}
										</div>
									</div>
								</div>
								<div className="card-middle-container delivery-payment-adress direction-between">
									<>
										<h4 className="delivery-payment-title">{i18next.t("space.address.2")}</h4>
										<div className="delivery-payment-adress-item-content dflex flex-wrap">
											{billingAddress.map(item => (
												item.isDefault && <div
													className={`item ${selectedBillingAddress === item.clientAddressId ? "item-default" : "item-choose-address"}`}
													key={`shipping-${item.clientAddressId}`}
													onClick={() => this.upsertSelectedAddressList(item.clientAddressId, typeBilling)}
												>
													<div className='item-content'>
														<div className="item-content-flex-box">
															<div className='info-box'>
																<p className="user-name">{item.name}</p>
																<p className="address">{`${item.address1} ${item.address2 || ''}`}</p>
																<p className="zip">{`${item.zip} ${item.city}`}</p>
																<p className="country">{item.country}</p>
																<p className="phone">{item.phone}</p>
															</div>
															<div
																className='del-btn c-pointer'
																onClick={(e) => this.toggleRemovePopup(item.clientAddressId, typeBilling, e)}
															>
															</div>
														</div>
														<span
															className="main-link"
															onClick={this.modifier.bind(this)}
														>{i18next.t("space.address.3")}</span>
													</div>
													{selectedBillingAddress === item.clientAddressId ?
														<button className="main-btn">{i18next.t("space.address.4")}</button>
														:
														<span
															className="main-link nowrap"
															onClick={() => { }}
														>{i18next.t("space.address.4")}</span>
													}
												</div>
											))}

											{!defaultBillingAddress &&
												<div className="delivery-payment-adress-item dflex flex-column justify-content-center">
													<h4 className="item-title">{i18next.t('product.basket.delivery.6')}</h4>
													<div
														className="delivery-payment-adress-item-plus dflex justify-content-center align-items-center"
														onClick={(e) => this.toggleAddressPopup("", typeBilling, e)}>
													</div>
												</div>
											}
										</div>
									</>
								</div>
								<div className="card-right-container direction-between">
									<h4 className="delivery-payment-title">{i18next.t('product.basket.delivery.1')}</h4>
									<div className="delivery-payment-price-content">
										{(STORE_DEPOT === "1" || STORE_DELIVERY === "1" || "") && (
											<ChooseNearestStore
												isReturn={false}
												classN={selectedPrice === "0" ? "active" : ""}
												selectedStore={selectedStore}
												storeDelivery={storeDelivery}
												from='deliveryPayment'
												handleClick={() => { this.setState({ selectedPrice: "0" }) }}
												setActivePrice={(number) => this.setState({ selectedPrice: number })}
												changeSelectedPrice={this.changeSelectedPrice}
											/>
										)}
									</div>
								</div>
							</div>
							<div className="cards-text-block">
								<div>{label}: <span className="livrasion-estimate-text">{getLivrasionEstimate(storeDelivery)}</span></div>
								{orderMessage && <span className="error-text">{orderMessage}</span>}
								{showMessage && <span className="error-text">{errorMessage}</span>}
							</div>
						</div>
						{basket && Object.keys(basket).length ?
							<div className="summary-container">
								<Summary items={basket} removeItem={removeItem} changeQuantity={changeQuantity} />
							</div> : null
						}
					</div>
					<div className="delivery-payment-right-container">
						<DiscountPayment classN={"desktopSize768"} />
						<Payment
							confirm={this.handleCreateOrder}
							basket={basket}
							discount={discount}
							totalPrice={totalPrice}
							payRef={payRef}
							selectedStore={selectedStore}
							shippingAddress={shippingAddress.find((item) => item.isDefault) || {}}
						/>
					</div>
				</div>
				{showAddressPopup &&
					<AddressPopup handleToggleAddressPopup={this.toggleAddressPopup}
						handleUpsertAddressItem={this.handleUpsertAddressItem} item={addressItem} />
				}
				{showConfirmPopup &&
					<ConfirmPopup handleCancel={this.toggleRemovePopup} handleConfirm={this.handleRemoveAddress}
						text={i18next.t('product.basket.address.12')}
						title={i18next.t('product.basket.address.11')} />
				}
				{showInfoPopup &&
					<ConfirmPopup handleCancel={this.handleCloseInfoPopup} text={i18next.t('modal.1')} title={""} footer={false} />
				}


				{showProductsInfo &&
					<ConfirmPopup
						handleCancel={() => this.setState({ showProductsInfo: '' })}
						text={showProductsInfo}
						title={""}
						footer={false}
					/>
				}

			</div>
		);
	}
}

const mapStateToProps = (state0: any): Props => {
	return {
		address: getMesAddress(state0),
		status: getStatus(state0),
		orderStatus: getorderStatus(state0),
		error: getError(state0),
		newOrder: getCreateOrder(state0),
		user: getUser(state0),
		mesAddressSend: getMesAddressSend(state0),
		locationSearchData: getLocationSearch(state0),
		userStore: getUserStore(state0),
		deliveryMethod: getDeliveryMethod(state0),
		basket: getBasket(state0),
		discount: getDiscount(state0),
		menuList: getMenu(state0),
		brandDetail: getBrandDetail(state0),
		webClient: getUserInfo(state0)
	}
};
const mapDispatchToProps = (dispatch): typeof MesAddressActionCreators => {
	return bindActionCreators({
		...MesAddressActionCreators,
		...OrderActionCreators,
		...StoreLocationActionCreators,
		...UserActionCreators,
		clickAndCollectStoresStart: LocationSearchActionCreators.clickAndCollectStoresStart
	}, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeliveryPayment));
